import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import MuiDialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import { withStyles } from '@mui/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import AvaDesktopDownloadImage from '../../../assets/images/avaCaptionsDesktopDownload.png';
import { getOS } from '../../utils';
const useStyles = makeStyles((theme) => ({
    title: {
        color: theme.palette.ava.blue,
        fontWeight: 600,
        fontSize: 30,
        marginTop: theme.spacing(3),
    },
    illustration: {
        maxWidth: '100%',
        marginTop: theme.spacing(6),
    },
    getAppButton: {
        textTransform: 'none',
        color: 'white',
        borderRadius: 24,
        backgroundColor: theme.palette.ava.blue,
        fontSize: 18,
        fontWeight: 600,
        borderLeftStyle: 'solid !important',
        borderColor: 'lightgray !important',
        height: 48,
        marginBottom: 12,
        '&:hover': {
            backgroundColor: theme.palette.ava.blue,
            color: 'white',
        },
        padding: '8px 20px',
        maxWidth: 380,
        width: '100%',
        whiteSpace: 'nowrap',
        margin: 'auto',
        display: 'block',
    },
    continueButton: {
        textDecoration: 'underline',
        fontSize: 15,
        margin: 'auto',
        display: 'block',
        marginTop: theme.spacing(3),
    },
}));
const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(8),
    },
}))(MuiDialogContent);
const DesktopAppCaptionsModal = ({ onClose }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const OS = getOS();
    return (React.createElement("div", null,
        React.createElement(Dialog, { "aria-labelledby": "customized-dialog-title", open: true, onClose: onClose },
            React.createElement(DialogContent, null,
                React.createElement(Typography, { align: "center", className: classes.title },
                    React.createElement("span", null, t('conversation.captionsModal.1clickCaptions'))),
                React.createElement("img", { className: classes.illustration, src: AvaDesktopDownloadImage, alt: "ava desktop download" }),
                React.createElement(Button, { onClick: () => {
                        window.open('/download');
                    }, className: classes.getAppButton }, t('conversation.captionsModal.getAva', { OS })),
                React.createElement(Button, { onClick: onClose, className: classes.continueButton }, t('conversation.captionsModal.continue'))))));
};
export default DesktopAppCaptionsModal;
