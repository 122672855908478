import Box from '@mui/material/Box';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { selectIsInConversation } from '../../selectors/conversation';
import { selectKeyboardInputShown } from '../../selectors/ui';
import { useAppSelector } from '../../store/store';
import { ConvoV2ControlBar } from './ConvoV2ControlBar';
import { ConvoV2ConversationHeader } from './ConvoV2ConversationHeader';
import { ConvoV2KeyboardInput } from './ConvoV2KeyboardInput';
import { ConvoV2LeftSidebar } from './ConvoV2LeftSidebar';
import { ConvoV2TranscriptList } from './ConvoV2TranscriptList';
import { ConvoV2TranscriptPlaceholder } from './ConvoV2TranscriptPlaceholder';
const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        width: '100%',
    },
    sidebar: {
        height: 'calc(var(--vh, 1vh) * 100)',
    },
    right: {
        flexGrow: 2,
        display: 'flex',
        flexDirection: 'column',
        height: 'calc(var(--vh, 1vh) * 100)',
        overflow: 'hidden',
        backgroundColor: theme.palette.ava.transparent.blue[theme.palette.mode],
    },
    transcripts: {
        width: '100%',
        overflow: 'auto',
        flexGrow: 2,
    },
}));
export const ConvoV2ConversationPage = React.memo(() => {
    const isInConversation = useAppSelector(selectIsInConversation);
    const keyboardInputShown = useAppSelector(selectKeyboardInputShown);
    const classes = useStyles();
    if (!isInConversation) {
        return null;
    }
    return (React.createElement(Box, { className: classes.root },
        React.createElement(ConvoV2LeftSidebar, { className: classes.sidebar }),
        React.createElement(Box, { className: classes.right },
            React.createElement(ConvoV2ConversationHeader, null),
            React.createElement(ConvoV2TranscriptPlaceholder, { className: classes.transcripts }),
            React.createElement(ConvoV2TranscriptList, { className: classes.transcripts }),
            React.createElement(ConvoV2ControlBar, { open: !keyboardInputShown }),
            React.createElement(ConvoV2KeyboardInput, { open: keyboardInputShown }))));
});
