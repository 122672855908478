var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { sleep } from '../../utils';
import availableVoices from '../../../assets/ttsVoices.json';
import { tts } from '../../services/api/ava';
import * as segment from '../../utils/segment';
import { TextToSpeechManager } from '../../utils/textToSpeech';
const getInitialState = () => {
    return {
        ttsVoices: new Map(),
        ttsError: '',
        ttsGender: undefined,
        isUsingTts: false,
        displayedItemsCount: 2,
        isTtsLoading: false,
        v1Voices: [],
    };
};
export const textToSpeechSlice = createSlice({
    name: 'textToSpeech',
    initialState: getInitialState,
    reducers: {
        setV1Voices(state, { payload }) {
            state.v1Voices = payload;
        },
        setTtsVoices(state, { payload }) {
            state.ttsVoices = payload;
        },
        setTtsError(state, { payload }) {
            state.ttsError = payload;
        },
        setIsUsingTts(state, { payload }) {
            state.isUsingTts = payload;
        },
        setUserTyping(state, { payload }) {
            state.userTyping = payload;
        },
        setDisplayedItemsCount(state, { payload }) {
            state.displayedItemsCount = payload;
        },
        setTtsVoice(state, { payload }) {
            state.currentSelectedVoice = payload;
            state.ttsGender = payload.gender;
        },
        setTtsVoicesFromJson(state, { payload }) {
            state.ttsVoices = payload;
        },
    },
    extraReducers(builder) {
        builder.addCase(v1FindBestTTSVoice.fulfilled, (state, { payload }) => {
            state.v1TtsBestVoice = payload;
        });
        builder.addCase(createTextToSpeechManager.fulfilled, (state, { payload }) => {
            if (payload)
                state.textToSpeechManager = payload;
        });
        builder.addCase(fetchTtsVoices.pending, (state) => {
            state.isTtsLoading = true;
        });
        builder.addCase(fetchTtsVoices.rejected, (state) => {
            state.isTtsLoading = false;
        });
        builder.addCase(fetchTtsVoices.fulfilled, (state, { payload }) => {
            state.ttsVoices = payload;
            state.isTtsLoading = false;
        });
        builder.addCase(fetchGenderPreference.fulfilled, (state, { payload }) => {
            state.ttsGender = payload;
        });
        builder.addCase(setTtsGenderThunk.fulfilled, (state, { payload }) => {
            state.ttsGender = payload;
        });
        builder.addCase(setTtsGenderThunk.rejected, (state) => {
            state.ttsGender = undefined;
        });
    },
});
export const textToSpeechReducer = textToSpeechSlice.reducer;
export const { setTtsError, setTtsVoices, setIsUsingTts, setUserTyping, setDisplayedItemsCount, setTtsVoice, setTtsVoicesFromJson, setV1Voices, } = textToSpeechSlice.actions;
export const v1FetchVoices = () => (dispatch) => {
    if (window.speechSynthesis) {
        const populateVoiceList = () => {
            const newVoices = window.speechSynthesis.getVoices();
            if (newVoices) {
                dispatch(setV1Voices(newVoices));
            }
        };
        populateVoiceList();
        if (window.speechSynthesis.onvoiceschanged !== undefined) {
            window.speechSynthesis.onvoiceschanged = populateVoiceList;
        }
    }
};
export const v1FindBestTTSVoice = createAsyncThunk('textToSpeech/v1FindBestTTS', (_, { getState }) => {
    const state = getState();
    const { speechLang } = state.scribeConversation;
    const { v1Voices } = state.textToSpeech;
    let voice;
    if (window.speechSynthesis && v1Voices.length) {
        let candidateVoices = v1Voices.filter((v) => v.lang === speechLang);
        if (!candidateVoices.length) {
            const pattern = /-|_/;
            const shortLang = speechLang.split(pattern)[0];
            candidateVoices = v1Voices.filter((v) => v.lang.split(pattern)[0] === shortLang);
        }
        if (candidateVoices.length === 1)
            [voice] = candidateVoices;
        if (candidateVoices.length > 1) {
            [voice] = candidateVoices;
        }
    }
    return voice;
});
export const setErrorAndReset = createAsyncThunk('textToSpeech/setErrorAndReset', (error, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    thunkAPI.dispatch(setTtsError(error));
    yield sleep(2000);
    thunkAPI.dispatch(setTtsError(''));
}));
export const fetchTtsVoices = createAsyncThunk('textToSpeech/fetchTtsVoices', (language, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    var _a, _b, _c;
    try {
        const state = thunkAPI.getState();
        const firebaseAuthUID = (_b = (_a = state.auth.firebaseUser) === null || _a === void 0 ? void 0 : _a.uid) !== null && _b !== void 0 ? _b : '';
        const lang = (_c = language.split('-')[0]) !== null && _c !== void 0 ? _c : 'en';
        const { data: voices } = yield tts.getTtsVoices({ firebaseAuthUID, lang });
        const ttsVoices = new Map();
        voices.forEach((value) => {
            const voice = {
                name: value.name,
                gender: value.gender,
                provider: value.provider,
                tones: value.tones,
                id: `${value.provider}-${value.voiceProviderId}`,
                voiceProviderId: value.voiceProviderId,
                urlAudio: value.urlAudio,
                availableInUserLanguage: value.availableInUserLanguage,
                selected: value.selected,
            };
            ttsVoices.set(voice.voiceProviderId, voice);
            // if user selected a voice last session let them have it load up
            if (value.selected) {
                thunkAPI.dispatch(setTtsVoice(value));
            }
        });
        return ttsVoices;
    }
    catch (error) {
        // we want to fallback on the json file if the backend fails
        console.error('Failed to fetch TTS voices from backend, using local JSON', error);
        const ttsVoices = new Map();
        Object.entries(availableVoices).forEach(([key, value]) => {
            const voice = {
                name: value.name,
                gender: value.gender,
                provider: value.provider,
                tones: value.tones,
                id: `${value.provider}-${value.voiceProviderId}`,
                voiceProviderId: value.voiceProviderId,
            };
            ttsVoices.set(key, voice);
        });
        thunkAPI.dispatch(setTtsVoicesFromJson(ttsVoices));
        return thunkAPI.rejectWithValue(error);
    }
}));
export const setTtsVoiceThunk = createAsyncThunk('texToSpeech/setTtsVoiceThunk', (voice, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    var _d, _e;
    try {
        const state = thunkAPI.getState();
        const dispatch = thunkAPI.dispatch;
        const firebaseAuthUID = (_e = (_d = state.auth.firebaseUser) === null || _d === void 0 ? void 0 : _d.uid) !== null && _e !== void 0 ? _e : '';
        segment.track('Updated Voice', {
            name: voice.voiceProviderId,
            gender: voice.gender,
            app_platform: window.isElectron ? 'desktop' : 'web',
        });
        dispatch(setTtsVoice(voice));
        dispatch(setDisplayedItemsCount(2));
        yield tts.postTtsVoicePreference({ firebaseAuthUID, voiceId: voice.id });
    }
    catch (error) {
        console.error('failed to update selected voice on server: ', error);
    }
}));
export const setTtsGenderThunk = createAsyncThunk('textToSpeech/setTtsGenderThunk', (gender, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    var _f, _g;
    try {
        const state = thunkAPI.getState();
        const firebaseAuthUID = (_g = (_f = state.auth.firebaseUser) === null || _f === void 0 ? void 0 : _f.uid) !== null && _g !== void 0 ? _g : '';
        segment.track('Gender Selected', {
            gender,
            app_platform: window.isElectron ? 'desktop' : 'web',
        });
        yield tts.postTtsGenderPreference({ firebaseAuthUID, gender });
        return gender;
    }
    catch (error) {
        console.error('failed to update selected gender on server: ', error);
    }
}));
export const fetchGenderPreference = createAsyncThunk('textToSpeech/fetchGenderPreference', (_, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    var _h, _j;
    try {
        const state = thunkAPI.getState();
        const firebaseAuthUID = (_j = (_h = state.auth.firebaseUser) === null || _h === void 0 ? void 0 : _h.uid) !== null && _j !== void 0 ? _j : '';
        const genderPreference = yield tts.getTtsGenderPreference({ firebaseAuthUID });
        return genderPreference.data;
    }
    catch (error) {
        console.error('Failed to fetch TTS Gender Preference from backend, defaulting to none', error);
    }
}));
export const createTextToSpeechManager = createAsyncThunk('textToSpeech/createTextToSpeechManager', (_, thunkAPI) => {
    const dispatch = thunkAPI.dispatch;
    const state = thunkAPI.getState();
    const { v1Socket } = state.v1Session;
    if (!v1Socket)
        return;
    return new TextToSpeechManager(v1Socket, dispatch);
});
