import SquareIcon from '@mui/icons-material/Square';
import { Popover } from '@mui/material';
import Box from '@mui/material/Box';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import React, { useCallback, useRef, useState } from 'react';
import CcIcon from '../../../assets/icons/convov2-cc.svg';
import KeyboardIcon from '../../../assets/icons/convov2-keyboard.svg';
import SettingsIcon from '../../../assets/icons/convov2-settings.svg';
import { useV1Socket } from '../../hooks/useV1Socket';
import { selectFirebaseUser } from '../../selectors/auth';
import { selectStatus } from '../../selectors/legacy-conversation';
import { selectAvaId } from '../../selectors/userProfile';
import { updateKeyboardInputShown } from '../../store/slices/uiState';
import { useAppDispatch, useAppSelector } from '../../store/store';
import { getDefaultRoomId, sendResetRoomIdMessage, sendRoomStateUpdateMessage } from '../../utils/ws-v1';
import { ConvoV2AudioControls } from './ConvoV2AudioControls';
import { ConvoV2CaptionQualityButton } from './ConvoV2CaptionQualityButton';
import { ConvoV2ControlBarButton } from './ConvoV2ControlBarButton';
import { ConvoV2SettingsCard } from './ConvoV2SettingsCard';
const useStyles = makeStyles((theme) => ({
    root: {
        flexShrink: 0,
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        height: 0,
        overflow: 'hidden',
        transition: 'transform 0.3s ease-in-out',
        transform: 'translateY(100%)',
    },
    content: {
        display: 'flex',
        height: 72,
        flexDirection: 'row',
        alignItems: 'center',
        backgroundColor: 'white',
        minWidth: '200px',
        gap: 12,
        padding: 15,
        borderRadius: '56px',
        boxShadow: '2px 2px 10px 0px rgba(0,0,0,0.1)',
        boxSizing: 'border-box',
    },
    rootOpen: {
        height: 120,
        overflow: 'hidden',
        transform: 'translateY(0%)',
    },
    stopButton: {
        border: `1px solid ${theme.palette.error.main}`,
    },
    stopSquare: {
        color: theme.palette.error.main,
    },
}));
export const ConvoV2ControlBar = React.memo(({ className, open }) => {
    const status = useAppSelector(selectStatus);
    const [ws] = useV1Socket();
    const avaId = useAppSelector(selectAvaId);
    const firebaseUser = useAppSelector(selectFirebaseUser);
    const settingsButtonRef = useRef(null);
    const [showSettings, setShowSettings] = useState(false);
    const dispatch = useAppDispatch();
    const classes = useStyles();
    const onShowKeyboard = useCallback(() => {
        dispatch(updateKeyboardInputShown(true));
    }, [dispatch, updateKeyboardInputShown]);
    const onShowSettings = useCallback(() => setShowSettings(true), [setShowSettings]);
    const onHideSettings = useCallback(() => setShowSettings(false), [setShowSettings]);
    return (React.createElement(Box, { className: classNames(classes.root, className, { [classes.rootOpen]: open }) },
        React.createElement(Box, { className: classes.content },
            React.createElement(ConvoV2AudioControls, null),
            React.createElement(ConvoV2ControlBarButton, { onClick: onShowKeyboard },
                React.createElement("img", { src: KeyboardIcon })),
            React.createElement(ConvoV2CaptionQualityButton, null),
            React.createElement(ConvoV2ControlBarButton, null,
                React.createElement("img", { src: CcIcon })),
            React.createElement(Box, { ref: settingsButtonRef },
                React.createElement(ConvoV2ControlBarButton, { onClick: onShowSettings },
                    React.createElement("img", { src: SettingsIcon }))),
            settingsButtonRef.current && (React.createElement(Popover, { open: showSettings, anchorEl: settingsButtonRef.current, anchorOrigin: { vertical: 'top', horizontal: 'center' }, transformOrigin: { vertical: 'bottom', horizontal: 'center' }, PaperProps: {
                    style: {
                        marginTop: -25,
                        minWidth: 340,
                        borderRadius: 20,
                        boxShadow: '2px 4px 12px 0px #7090B040',
                    },
                } },
                React.createElement(ConvoV2SettingsCard, { onClose: onHideSettings }))),
            React.createElement(ConvoV2ControlBarButton, { className: classes.stopButton, onClick: () => {
                    // TODO: This is copied from ConversationPage. Abstract out.
                    if (status.host && status.host.avaId === avaId) {
                        if (ws)
                            sendRoomStateUpdateMessage(ws, { value: 'ended' });
                    }
                    if (ws)
                        sendResetRoomIdMessage(ws, getDefaultRoomId(firebaseUser === null || firebaseUser === void 0 ? void 0 : firebaseUser.uid));
                } },
                React.createElement(SquareIcon, { className: classes.stopSquare })))));
});
