import { MenuItem, Select } from '@mui/material';
import React, { useCallback } from 'react';
import { NO_TRANSLATION, useTranslationLangSwitcher } from '../../hooks/useTranslationLangSwitcher';
export function ConvoV2TranslationLangSelector() {
    var _a;
    const { activeTranslationLang, allTranslationLangs, switchTranslationLang } = useTranslationLangSwitcher();
    const onChange = useCallback((event) => {
        switchTranslationLang(event.target.value);
    }, [switchTranslationLang]);
    return (React.createElement(Select, { value: (_a = activeTranslationLang === null || activeTranslationLang === void 0 ? void 0 : activeTranslationLang.languageCode) !== null && _a !== void 0 ? _a : NO_TRANSLATION, onChange: onChange, 
        // Without this, the whole settings card is hidden as soon as you open the select
        MenuProps: { disablePortal: true } },
        React.createElement(MenuItem, { key: NO_TRANSLATION, value: NO_TRANSLATION }, "---"),
        allTranslationLangs.map((translationLang) => (React.createElement(MenuItem, { key: translationLang.languageCode, value: translationLang.languageCode }, translationLang.label)))));
}
