import { makeStyles } from '@mui/styles';
const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        width: '100%',
    },
    topContainer: {
        display: 'flex',
        alignItems: 'end',
        justifyContent: 'space-between',
        width: '100%',
        marginBottom: 5,
        backgroundColor: 'transparent',
        visibility: 'hidden',
        opacity: 0,
        transition: 'opacity 300ms',
        overflow: 'none',
    },
    topContainerLeft: {
        display: 'flex',
        alignItems: 'end',
    },
    visible: {
        visibility: 'visible',
        opacity: 1,
    },
    participants: {
        flex: 1,
        display: 'flex',
        alignItems: 'center',
        paddingLeft: 6,
        height: '100%',
        overflowX: 'scroll',
        WebkitAppRegion: 'no-drag',
        '&::-webkit-scrollbar': {
            height: '0 !important',
            width: '0 !important',
        },
    },
    conversationButtons: {
        display: 'flex',
        background: 'linear-gradient(229.3deg, #000000 5.57%, #000E34 80.9%)',
        borderRadius: 12,
        overflow: 'hidden',
        transition: 'opacity 200ms',
        WebkitAppRegion: 'no-drag',
    },
    conversationButton: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: 40,
        width: 42,
        backgroundColor: theme.palette.ccMode.conversationButtonsBg,
        border: 'none',
        cursor: 'pointer',
        transition: 'background-color 200ms',
        userSelect: 'none',
        WebkitAppearance: 'none',
        '&:hover': {
            backgroundColor: theme.palette.ava.grey5,
        },
        '&:active': {
            backgroundColor: theme.palette.ava.deepblue2,
        },
        '&:focus': {
            outline: 'none',
        },
    },
    closeButton: {
        '&:hover': {
            backgroundColor: `${theme.palette.ava.red2} !important`,
        },
        '&:active': {
            backgroundColor: theme.palette.ava.states.error,
        },
    },
    closeIcon: {
        height: '1.2em',
        width: '1.2em',
        fill: '#EEEEEE',
    },
    conversationContainer: {
        position: 'relative',
        zIndex: 101,
        overflow: 'hidden',
        flex: 1,
        background: theme.palette.ccMode.backgroundGradient,
        height: '100%',
        borderRadius: 20,
        padding: 3,
    },
    conversationDraggableContainer: {
        WebkitAppRegion: 'drag',
        height: '100%',
        display: 'flex',
    },
    readOnlyConversation: {
        paddingLeft: 12,
        paddingRight: 12,
    },
    offlineBanner: {
        position: 'absolute',
        top: 0,
    },
    conversationContainerKeyboardOpen: {
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
    },
    loadingTextContainer: {
        flex: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    loadingText: {
        display: 'block',
        fontSize: 40,
        userSelect: 'none',
    },
    controlsContainer: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingBottom: 6,
        minWidth: 118,
    },
    volumeController: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        opacity: (props) => (props.showBubbleAboveCCMode ? 1 : 0),
        transition: 'opacity 200ms',
    },
    audioRecorder: {
        position: 'unset',
        top: 'unset',
        left: 'unset',
        transform: 'unset',
        marginBottom: 'unset !important',
    },
    tooltipPlacementTop: {
        margin: '8px 0',
    },
    openKeyboardInputButton: {
        padding: 4,
        backgroundColor: 'transparent',
        borderRadius: 4,
        outline: 'none',
        border: 'none',
        transition: 'background-color .2s',
        WebkitAppRegion: 'no-drag',
        userSelect: 'none',
        '&:hover': {
            background: theme.palette.ava.grey5,
        },
    },
    transcriptsContainer: {
        position: 'relative',
        flex: 1,
    },
    inviteCardContainer: {
        position: 'fixed',
        right: 3,
        zIndex: 101,
    },
    bottomContainer: {
        height: 0,
        overflowY: 'auto',
        visibility: 'hidden',
    },
    keyboardInputOpen: {
        minHeight: 86,
        height: (props) => (props.fontSize && props.fontSize * 1.75) || 86,
        visibility: 'visible',
    },
}));
export default useStyles;
