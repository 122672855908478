var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import CampaignIcon from '@mui/icons-material/Campaign';
import { Card, ClickAwayListener, IconButton, Popover, Tooltip, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ttsDisabled from '../../../assets/icons/tts_disabled.svg';
import usePrevious from '../../hooks/usePrevious';
import { selectCurrentSelectedVoice, selectDisplayedItemsCount, selectIsUsingTts, selectTtsGender, } from '../../selectors/textToSpeech';
import { selectElectronCaptionMode } from '../../selectors/ui';
import { setIsUsingTts } from '../../store/slices/textToSpeech';
import { useAppDispatch, useAppSelector } from '../../store/store';
import { sleep } from '../../utils';
import { TtsSelector } from './TtsSelector';
const useStyles = makeStyles((theme) => ({
    ccButton: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: 32,
        width: 32,
        color: (props) => (props.isUsingTts ? theme.palette.ava.blue2 : 'white'),
        backgroundColor: 'transparent',
        '&:hover': {
            backgroundColor: theme.palette.ava.deepBlue,
        },
        boxShadow: 'none',
        position: 'relative',
        bottom: 0,
        right: 0,
    },
    button: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: 56,
        width: 56,
        color: 'white',
        backgroundColor: (props) => (props.isUsingTts ? theme.palette.ava.blue : theme.palette.ava.blue4),
        '&:hover': {
            backgroundColor: (props) => (props.isUsingTts ? theme.palette.ava.deepBlue : theme.palette.ava.blue4),
        },
        boxShadow: '0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)',
        position: 'absolute',
        bottom: 30,
        right: 85,
        zIndex: 101,
    },
    popover: {
        marginLeft: 33,
        borderRadius: '20px',
        overflowY: 'hidden',
        height: 'max-content',
    },
    popoverContainer: {
        width: (props) => (props.showVoices || !props.electronCaptionMode ? 350 : 400),
        height: '100%',
        display: 'inline-flex',
        flexDirection: 'column',
        padding: (props) => (props.showVoices ? '10px 20px 0' : '20px'),
        gap: (props) => (props.showVoices ? 10 : 16),
        alignItems: 'center',
        background: (props) => (props.electronCaptionMode ? theme.palette.ava.darkBg2 : 'transparent'),
        borderRadius: '20px',
        transition: 'width 0.5s ease',
    },
    topContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: '8px',
        width: '100%',
        alignItems: 'center',
        alignSelf: 'stretch',
    },
    title: {
        fontWeight: 700,
        color: (props) => (props.electronCaptionMode || theme.palette.mode === 'dark' ? 'white' : 'black'),
        fontSize: 20,
    },
    bottomContainer: {
        display: 'flex',
        maxHeight: 300,
        overflowY: (props) => (props.showVoices ? 'auto' : 'unset'),
        flexDirection: (props) => (props.electronCaptionMode && !props.showVoices ? 'row' : 'column'),
        gap: 12,
        width: '100%',
        alignItems: 'center',
        marginBottom: (props) => (props.electronCaptionMode ? 0 : 16),
    },
    titleContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    close: {
        position: 'absolute',
        right: -5,
    },
    choicesContainer: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
    },
    paragraph: {
        textAlign: 'center',
        marginTop: -15,
    },
    choices: {
        color: theme.palette.ava.navy,
        background: 'white',
        border: '1px solid white',
        '&:hover': {
            backgroundColor: 'transparent',
            color: 'white',
        },
        fontSize: (props) => (props.lang === 'fr' ? 12 : 14),
        width: 120,
        height: 32,
        padding: '8px 15px',
    },
    tooltip: {
        padding: '2.5px 8px',
        backgroundColor: theme.palette.ava.grey4,
        borderRadius: 4,
        fontSize: 14,
        textAlign: 'center',
    },
    sendToolTipBottom: {
        position: 'relative',
        top: (props) => (props.electronCaptionMode ? 75 : 10),
        right: 15,
    },
}));
function TtsButton() {
    const [open, setOpen] = useState(false);
    const parentRef = useRef(null);
    const { t, i18n } = useTranslation();
    const genderPreference = useAppSelector(selectTtsGender);
    const displayedItemsCount = useAppSelector(selectDisplayedItemsCount);
    const dispatch = useAppDispatch();
    const isUsingTts = useAppSelector(selectIsUsingTts);
    const electronCaptionMode = useAppSelector(selectElectronCaptionMode);
    const currentTtsVoiceSelected = useAppSelector(selectCurrentSelectedVoice);
    const prevOpen = usePrevious(open);
    const [isButtonHovered, setIsButtonHovered] = useState(false);
    const showVoices = genderPreference !== undefined;
    const classes = useStyles({
        isUsingTts,
        lang: i18n.language,
        electronCaptionMode,
        showVoices,
        items: displayedItemsCount,
    });
    const handleButtonClick = () => {
        if (!genderPreference || !currentTtsVoiceSelected) {
            setOpen(true);
        }
        else {
            dispatch(setIsUsingTts(!isUsingTts));
        }
    };
    const handleCancel = () => {
        setOpen(false);
        dispatch(setIsUsingTts(false));
    };
    useEffect(() => {
        // we need to force re-render of popover to prevent the awkward positioning of the popover on change of height or width
        // when we change from gender selection screen to voice selection screen
        if (showVoices && open) {
            const asyncFunc = () => __awaiter(this, void 0, void 0, function* () {
                yield sleep(0);
                setOpen(false);
                yield sleep(0);
                setOpen(true);
            });
            asyncFunc();
        }
    }, [genderPreference]);
    useEffect(() => {
        // we want to set the isUsingTts to true only if the user has selected a voice and closes the menu
        if (prevOpen && !open && currentTtsVoiceSelected) {
            dispatch(setIsUsingTts(true));
        }
    }, [open, currentTtsVoiceSelected]);
    useEffect(() => {
        // initialize to not using tts
        dispatch(setIsUsingTts(false));
    }, []);
    return (React.createElement("div", { style: { marginRight: 10 } },
        React.createElement("div", { ref: electronCaptionMode ? parentRef : undefined },
            React.createElement(Popover, { anchorEl: open ? parentRef.current : null, open: open, anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'left',
                }, transformOrigin: {
                    vertical: 'bottom',
                    horizontal: 'right',
                }, PaperProps: { className: classes.popover } },
                React.createElement("div", { style: { visibility: open ? 'visible' : 'hidden' } },
                    React.createElement(ClickAwayListener, { onClickAway: () => handleCancel() },
                        React.createElement(Card, { className: classes.popoverContainer },
                            React.createElement("div", { className: classes.topContainer },
                                React.createElement(Typography, { variant: "h4", align: "center", width: '100%', className: classes.title }, t('profiling.voiceSelection.heading2'))),
                            React.createElement("div", { className: classes.bottomContainer },
                                React.createElement(TtsSelector, { closeMenu: () => {
                                        setOpen(false);
                                    }, buttonPopupMenu: true, showVoices: genderPreference !== undefined }))))))),
        React.createElement(Tooltip, { open: isButtonHovered && isUsingTts, classes: { tooltip: classes.tooltip, tooltipPlacementBottom: classes.sendToolTipBottom }, title: t('ccMode.keyboard.tooltip.tts'), placement: "bottom", disableInteractive: true, onMouseEnter: () => setIsButtonHovered(true), onMouseLeave: () => setIsButtonHovered(false) },
            React.createElement(IconButton, { ref: !electronCaptionMode ? parentRef : undefined, className: classNames({
                    [classes.button]: !electronCaptionMode,
                    [classes.ccButton]: electronCaptionMode,
                }), onClick: handleButtonClick }, !isUsingTts ? React.createElement("img", { src: ttsDisabled, alt: "text-to-speech disabled" }) : React.createElement(CampaignIcon, null)))));
}
export default TtsButton;
