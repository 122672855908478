import LanguageIcon from '@mui/icons-material/Language';
import SpokenLanguageIcon from '@mui/icons-material/Sms';
import TextFieldsOutlined from '@mui/icons-material/TextFieldsOutlined';
import { Box, ClickAwayListener } from '@mui/material';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import ConversationModeIcon from '../../../assets/icons/group-settings.svg';
import { selectCanSwitchTranslation } from '../../selectors/avaTranslate';
import { selectCanSwitchConversationMode } from '../../selectors/combined';
import { useAppSelector } from '../../store/store';
import { ConvoV2ConversationModeSelector } from './ConvoV2ConversationModeSelector';
import { ConvoV2FontSizeInput } from './ConvoV2FontSizeInput';
import { ConvoV2SpokenLangSelector } from './ConvoV2SpeechLangSelector';
import { ConvoV2TranslationLangSelector } from './ConvoV2TranslationLangSelector';
const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        gap: 16,
        padding: '28px 20px',
    },
    item: {
        display: 'flex',
        width: '100%',
        gap: 8,
    },
    itemLeft: {
        width: 24,
    },
    itemRight: {
        flexGrow: 2,
    },
    itemLabel: {
        fontSize: 16,
        fontWeight: 400,
        color: theme.palette.ava.grey5,
    },
}));
function ConvoV2SettingsCardItem({ title, icon, children }) {
    const classes = useStyles();
    return (React.createElement(Box, { className: classes.item },
        React.createElement(Box, { className: classes.itemLeft }, icon),
        React.createElement(Box, { className: classes.itemRight },
            React.createElement(Box, { className: classes.itemLabel }, title),
            children)));
}
export function ConvoV2SettingsCard({ className, onClose }) {
    const classes = useStyles();
    const { t } = useTranslation();
    const canSwitchConversationMode = useAppSelector(selectCanSwitchConversationMode);
    const canSwitchTranslation = useAppSelector(selectCanSwitchTranslation);
    return (React.createElement(ClickAwayListener, { onClickAway: onClose },
        React.createElement(Box, { className: classNames(classes.root, className) },
            React.createElement(ConvoV2SettingsCardItem, { title: t('editorSettings.fontSize'), icon: React.createElement(TextFieldsOutlined, null) },
                React.createElement(ConvoV2FontSizeInput, null)),
            canSwitchConversationMode && (React.createElement(ConvoV2SettingsCardItem, { title: t('conversation.conversationMode.name'), icon: React.createElement("img", { src: ConversationModeIcon }) },
                React.createElement(ConvoV2ConversationModeSelector, null))),
            React.createElement(ConvoV2SettingsCardItem, { title: t('convoV2.settings.spokenLanguage'), icon: React.createElement(SpokenLanguageIcon, null) },
                React.createElement(ConvoV2SpokenLangSelector, null)),
            canSwitchTranslation && (React.createElement(ConvoV2SettingsCardItem, { title: t('convoV2.settings.translationLanguage'), icon: React.createElement(LanguageIcon, null) },
                React.createElement(ConvoV2TranslationLangSelector, null))))));
}
