import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import WebsocketReconnectingBanner from '../../../components/Network/WebsocketReconnectingBanner';
import { useNotifications } from '../../../hooks/useNotifications';
import { useSoloDiaNotifications } from '../../../hooks/useSoloDiaNotifications';
import ConversationEndModals from '../../../modules/conversation/ConversationEndModals';
import ScribeTranscriptListContainer from '../../../modules/scribe/ScribeTranscriptListContainer';
import ScribeVolumeController from '../../../modules/scribe/ScribeVolumeController';
import SoloDiaSpeakerNameModal, { SoloDiaNameModalProvider } from '../../../modules/scribe/SoloDiaSpeakerNameModal';
import { selectConversationMuted, selectShouldShowSoloDiaNotification } from '../../../selectors/combined';
import { selectIsInConversation, selectJoiningStatus } from '../../../selectors/conversation';
import { selectParticipants, selectStatusScribe } from '../../../selectors/legacy-conversation';
import { selectIsScribing } from '../../../selectors/scribe';
import { selectUserTyping } from '../../../selectors/textToSpeech';
import { selectFullScreen, selectInsertSpeakerNameMode } from '../../../selectors/ui';
import { selectAvaId } from '../../../selectors/userProfile';
import { setJoiningStatus } from '../../../store/slices/conversation';
import { useAppDispatch, useAppSelector } from '../../../store/store';
import useStyles from './ConversationView.styles';
const ConversationView = ({ conversationContainerHovered }) => {
    const classes = useStyles({ conversationContainerHovered });
    const { t } = useTranslation();
    const fullScreen = useAppSelector(selectFullScreen);
    const insertSpeakerNameMode = useAppSelector(selectInsertSpeakerNameMode);
    const scribing = useAppSelector(selectIsScribing);
    const joiningStatus = useAppSelector(selectJoiningStatus);
    const muted = useAppSelector(selectConversationMuted);
    const isInConversation = useAppSelector(selectIsInConversation);
    const statusScribe = useAppSelector(selectStatusScribe);
    const avaId = useAppSelector(selectAvaId);
    const statusParticipants = useAppSelector(selectParticipants);
    const shouldShowSoloDiaNotification = useAppSelector(selectShouldShowSoloDiaNotification);
    const userTyping = useAppSelector(selectUserTyping);
    const dispatch = useAppDispatch();
    const { notify } = useNotifications();
    const { notifySoloDiaInit } = useSoloDiaNotifications();
    const [notificationShowed, setNotificationShowed] = useState(false);
    useEffect(() => {
        if (joiningStatus === 'accepted') {
            notify(t('conversation.inviteCard.joinedAvaRoom'));
            dispatch(setJoiningStatus('none'));
        }
    }, [joiningStatus]);
    useEffect(() => {
        if (shouldShowSoloDiaNotification && !notificationShowed) {
            notifySoloDiaInit({
                disableWindowBlurListener: true,
                persist: true,
                preventDuplicate: true,
            });
            setNotificationShowed(true);
        }
    }, [shouldShowSoloDiaNotification]);
    useEffect(() => {
        if (shouldShowSoloDiaNotification && !notificationShowed) {
            notify('', {
                variant: 'soloDia',
                disableWindowBlurListener: true,
                persist: true,
            });
            setNotificationShowed(true);
        }
    }, [shouldShowSoloDiaNotification]);
    useEffect(() => {
        if (userTyping && userTyping.avaId !== avaId) {
            const message = userTyping.userName + ' ' + t('conversation.notifications.isTyping');
            notify(message);
        }
    }, [userTyping]);
    return (React.createElement("div", { style: { display: 'flex' } },
        React.createElement(WebsocketReconnectingBanner, { className: classes.reconnectBanner }),
        insertSpeakerNameMode && (React.createElement("div", { style: { backgroundColor: 'darksalmon', width: '100%', height: 30, textAlign: 'center' } }, "Insert Speaker Name mode")),
        scribing && statusScribe && statusScribe.status === 'leaving' && (React.createElement("div", { style: {
                backgroundColor: 'darksalmon',
                width: '100%',
                height: 30,
                textAlign: 'center',
                padding: 5,
                position: 'relative',
                zIndex: 102,
            } },
            React.createElement("span", null, t('scribeEndRequested')))),
        isInConversation && (React.createElement(SoloDiaNameModalProvider, null,
            React.createElement(ScribeTranscriptListContainer, null),
            React.createElement(SoloDiaSpeakerNameModal, null))),
        window.isElectron && !muted && isInConversation && !scribing && (React.createElement(ScribeVolumeController, { className: classes.volumeController })),
        statusParticipants && !scribing && !fullScreen && isInConversation && (React.createElement(React.Fragment, null,
            React.createElement(ConversationEndModals, null)))));
};
export default ConversationView;
