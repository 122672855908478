import { createSelector } from 'reselect';
const selectAuth = (state) => state.auth;
export const selectFirebaseUser = createSelector([selectAuth], (auth) => auth.firebaseUser);
export const selectConfigEnableSSO = createSelector([selectAuth], (auth) => auth.firebaseRemoteConfig.webEnableSSO);
export const selectConfigUseWebRTC = createSelector([selectAuth], (auth) => auth.firebaseRemoteConfig.webUseWebRTC);
export const selectConfigEnableSolodia = createSelector([selectAuth], (auth) => auth.firebaseRemoteConfig.webEnableSolodia);
export const selectSoloDiaTimeout = createSelector([selectAuth], (auth) => auth.firebaseRemoteConfig.soloDiaTimeout);
export const selectFirebaseAuthTriggered = createSelector([selectAuth], (auth) => auth.firebaseAuthTriggered);
export const selectFirebaseAuthInitiated = createSelector([selectAuth], (auth) => auth.firebaseAuthInitiated);
export const selectIsTextToSpeechV2 = createSelector([selectAuth], (auth) => auth.firebaseRemoteConfig.isTextToSpeechV2);
