import { useTheme } from '@mui/material';
import React from 'react';
const SendIconCC = ({ buttonState }) => {
    const theme = useTheme();
    const buttonStateColors = {
        disabled: theme.palette.ava.grey5,
        active: theme.palette.ava.blue2,
        inactive: 'white',
    };
    const fillColor = buttonStateColors[buttonState];
    return (React.createElement("svg", { width: "32", height: "32", viewBox: "0 0 32 32", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("path", { d: "M21.037 14.9664L11.8161 11.0864C11.4277 10.9229 10.9888 10.9948 10.6709 11.2739C10.3529 11.5531 10.2199 11.9833 10.3238 12.3966L11.1446 15.6605H15.163C15.3479 15.6605 15.4979 15.8125 15.4979 16C15.4979 16.1875 15.3479 16.3396 15.163 16.3396H11.1446L10.3238 19.6034C10.2199 20.0167 10.3529 20.4469 10.6709 20.7261C10.9895 21.0058 11.4284 21.0768 11.8162 20.9136L21.037 17.0337C21.4569 16.857 21.7176 16.4609 21.7176 16C21.7176 15.5391 21.4569 15.143 21.037 14.9664Z", fill: fillColor, stroke: "none" })));
};
export default SendIconCC;
