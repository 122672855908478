import { makeStyles } from '@mui/styles';
import classnames from 'classnames';
import { useLottie } from 'lottie-react';
import React, { useEffect } from 'react';
import { logout } from '../../store/slices/auth';
import { useAppDispatch } from '../../store/store';
import loaderAnimation from './loader.json';
const useStyles = makeStyles((theme) => ({
    root: {
        position: 'fixed',
        top: '0',
        left: '0',
        right: '0',
        bottom: '0',
        display: 'flex',
        top: '0',
        flexDirection: 'column',
        zIndex: 1400,
    },
    wrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'fixed',
        width: '100vw',
        height: '100vh',
        background: theme.palette.ava.transparent.black[theme.palette.mode],
    },
}));
export default function Loading({ className, children }) {
    const classes = useStyles();
    const dispatch = useAppDispatch();
    const { View } = useLottie({
        animationData: loaderAnimation,
        loop: true,
        autoplay: true,
        className: classes.loader,
    }, {
        height: 150,
    });
    useEffect(() => {
        // on login users are supposed to be redirected and this loader renders while that happens
        // this is a failsafe to prevent an infinite loader in the case that the redirect fails or never issues for whatever reason
        const logoutTimeout = setTimeout(() => {
            logout(dispatch);
        }, 60000);
        return () => {
            clearTimeout(logoutTimeout);
        };
    }, []);
    return (React.createElement("div", { className: classnames(classes.root, className) },
        children,
        React.createElement("div", { className: classes.wrapper }, View)));
}
