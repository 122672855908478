// Responsible for managing the different states of the CC mode window.
//
// Currently, just the conversation view is available. With the new CC
// navigation design, there will be two other states while staying in this CC
// mode window:
//  - Rating: Displays a rating section for the user to rate the conversation
//  - Home: Displays a button to quickly start a conversation or close the app
import React, { useContext, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { createNewConversation } from '../../../actions';
import { SiteThemeContext } from '../../../contexts/SiteThemeProvider';
import usePrevious from '../../../hooks/usePrevious';
import DesktopCCLayout from '../../../Layouts/DesktopCCLayout';
import { selectFirebaseUser } from '../../../selectors/auth';
import { selectCCMode, selectShowBubbleAboveCCMode } from '../../../selectors/ccMode';
import { selectIsInConversation } from '../../../selectors/conversation';
import { selectHaveTranscripts } from '../../../selectors/legacy-conversation';
import { selectIsInviteCardOpen } from '../../../selectors/ui';
import { addNotification, setCCMode, setShowBubbleAboveCCModeDebounced } from '../../../store/slices/ccMode';
import { setElectronCaptionMode, setFullScreen, setIsInviteCardOpen, toggleSideBar, updateKeyboardInputShown, } from '../../../store/slices/uiState';
import { useAppDispatch, useAppSelector } from '../../../store/store';
import { getOS, isLinux, isWindows } from '../../../utils';
import { getAppVersion, getIsMac, ipcRendererWithDeregistering } from '../../../utils/electron';
import { startTour } from '../../../utils/intercom';
import CCConversationView from '../CCConversationView/CCConversationView';
import HomeView from '../HomeView/HomeView';
import RatingView from '../RatingView/RatingView';
import useStyles from './CCPage.styles';
const CCPage = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const firebaseUser = useAppSelector(selectFirebaseUser);
    const isInviteCardOpen = useAppSelector(selectIsInviteCardOpen);
    const isInConversation = useAppSelector(selectIsInConversation);
    const dispatch = useAppDispatch();
    const previousTheme = useRef(undefined);
    const ccMode = useAppSelector(selectCCMode);
    const showBubbleAboveCCMode = useAppSelector(selectShowBubbleAboveCCMode);
    const { handleThemeChange, theme: currentTheme } = useContext(SiteThemeContext);
    const goInFloatingModeAndAttemptStartConversation = () => {
        window.electronIPC.sendGoInFloatingMode();
        if (firebaseUser && !firebaseUser.isAnonymous) {
            dispatch(setCCMode('conversation'));
            dispatch(createNewConversation());
        }
    };
    // Mac users can close the application and still be open in the background.
    // If the user closed the application from CC widget mode, then opening the
    // app again will start a new conversation immediately.
    useEffect(() => {
        var _a;
        dispatch(toggleSideBar(false));
        dispatch(setFullScreen(true));
        dispatch(updateKeyboardInputShown(false));
        (_a = window.electronIPC) === null || _a === void 0 ? void 0 : _a.sendSetInCaptionMode(true);
        dispatch(setElectronCaptionMode(true));
        if (window.isElectron) {
            ipcRendererWithDeregistering.addEventListener('window-activated', goInFloatingModeAndAttemptStartConversation);
        }
        return () => {
            var _a, _b;
            dispatch(setFullScreen(false));
            (_a = window.electronIPC) === null || _a === void 0 ? void 0 : _a.sendSetInCaptionMode(false);
            dispatch(setElectronCaptionMode(false));
            dispatch(toggleSideBar(true));
            if (getIsMac()) {
                // We're on mac. Let's show Intercom Tour, only once!
                (_b = window.electronIPC) === null || _b === void 0 ? void 0 : _b.invokeHasSeenInternalAudioMicTour().then((hasAlreadySeenTour) => {
                    if (!hasAlreadySeenTour) {
                        startTour(209258);
                    }
                });
            }
            if (window.isElectron) {
                ipcRendererWithDeregistering.removeEventListener('window-activated', goInFloatingModeAndAttemptStartConversation);
            }
        };
    }, []);
    const prevIsInConversation = usePrevious(isInConversation);
    useEffect(() => {
        if (isInConversation && !prevIsInConversation) {
            if (currentTheme === 'light') {
                previousTheme.current = 'light';
                handleThemeChange('dark');
            }
        }
        if (!isInConversation && prevIsInConversation) {
            if (getOS() === 'Mac') {
                window.electronIPC.sendDeactivateMacInternalAudio();
            }
            const haveTranscripts = selectHaveTranscripts(window.store.getState());
            dispatch(setCCMode(haveTranscripts ? 'rating' : 'home'));
        }
        return () => {
            if (previousTheme.current) {
                handleThemeChange(previousTheme.current);
                previousTheme.current = undefined;
            }
        };
    }, [isInConversation]);
    useEffect(() => {
        if (window.electronIPC) {
            window.electronIPC.sendCCMode(ccMode);
        }
        if (ccMode === 'conversation') {
            // This code is temporarily here to facilitate conversion from squirrel
            // to nsis. See WEB-173 for more details.
            const version = getAppVersion();
            // version is either our version name, or (if that's not available) electron
            // version. The way to distinguish this is by knowing that electron versions
            // are 18+, and our versions are 1.6+
            const match = version && version.match(/^\d\.\d\.\d/);
            const intVersion = match && parseInt(match[0].replaceAll('.', ''));
            if (isWindows && match && intVersion < 172) {
                dispatch(addNotification({
                    text: t('ccMode.newVersionAvailable'),
                    timestamp: Date.now(),
                    type: 'default',
                    link: 'https://cc.ava.me/download',
                    linkText: t('ccMode.update'),
                    duration: 10000,
                }));
            }
        }
        dispatch(setShowBubbleAboveCCModeDebounced(false));
    }, [ccMode]);
    // Electron has a limitation of not being able to click through a transparent
    // window. The workaround is to directly handle ignoring mouse events to allow
    // clicks to pass through the window. If the invisible items are hidden, we
    // know to ignore mouse events.
    //
    // This is not done for Windows machine because when entering a draggable
    // region, all pointer events are lost. This would cause showBubbleAboveCCMode
    // to become false and ignore mouse events when it shouldn't have.
    useEffect(() => {
        const currentWindow = window.electronCurrentWindow;
        if (!isLinux && !isWindows) {
            if (showBubbleAboveCCMode) {
                currentWindow.setIgnoreMouseEvents(false);
            }
            else {
                currentWindow.setIgnoreMouseEvents(true, { forward: true });
                if (isInviteCardOpen) {
                    dispatch(setIsInviteCardOpen(false));
                }
            }
        }
    }, [showBubbleAboveCCMode]);
    return (React.createElement(DesktopCCLayout, null,
        React.createElement("div", { className: classes.root, onMouseEnter: () => {
                if (isWindows || isLinux)
                    dispatch(setShowBubbleAboveCCModeDebounced(true));
            }, onMouseLeave: () => dispatch(setShowBubbleAboveCCModeDebounced(false)) },
            ccMode === 'home' && React.createElement(HomeView, null),
            ccMode === 'conversation' && React.createElement(CCConversationView, null),
            ccMode === 'rating' && React.createElement(RatingView, null))));
};
export default CCPage;
