import * as segment from './segment';
const STOPWATCH_DATA = {};
if (localStorage.getItem('stopwatch_index_init')) {
    STOPWATCH_DATA['init'] = localStorage.getItem('stopwatch_index_init');
}
export const startStopwatch = (name) => {
    STOPWATCH_DATA[name] = Date.now();
};
export const stopStopwatch = (name) => {
    if (STOPWATCH_DATA[name]) {
        const elapsed = Date.now() - STOPWATCH_DATA[name];
        delete STOPWATCH_DATA[name];
        return elapsed;
    }
    delete STOPWATCH_DATA[name];
    return 0;
};
export const stopAndTrack = (name, properties = {}, options = {}) => {
    const elapsed = stopStopwatch(name);
    // console.log(`stopAndTrack for ${name} with elapsed ${elapsed}`);
    if (elapsed) {
        segment.track(`Web - Stopwatch ${name}`, Object.assign(Object.assign({}, properties), { elapsed }), options);
    }
    else {
        segment.track(`Web - Stopwatch Not Started ${name}`, Object.assign({}, properties), options);
    }
};
