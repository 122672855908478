import Button from '@mui/material/Button';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import React from 'react';
const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        width: 44,
        minWidth: 44,
        height: 44,
        border: '1px solid rgba(0, 0, 0, 0.2)',
        borderRadius: '50%',
    },
}));
export const ConvoV2ControlBarButton = React.memo(({ children, className, onClick }) => {
    const classes = useStyles();
    return (React.createElement(Button, { onClick: onClick, className: classNames(classes.root, className) }, children));
});
