import CloseIcon from '@mui/icons-material/Close';
import { Tooltip, useMediaQuery } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { selectElectronCaptionMode } from '../../selectors/ui';
import { useAppSelector } from '../../store/store';
const useStyles = makeStyles((theme) => ({
    tooltip: {
        backgroundColor: theme.palette.ava.blue2,
        boxShadow: '4px 2px 12px 0px rgba(112, 144, 176, 0.25)',
        padding: 16,
        maxWidth: 355,
        height: 80,
        borderRadius: 20,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
        gap: 12,
    },
    arrow: {
        color: theme.palette.ava.blue2,
    },
    text: {
        color: 'white',
        fontSize: 18,
        fontWeight: 600,
        alignSelf: 'center',
    },
    closeButton: {
        color: 'white',
        padding: 0,
        width: 16,
        height: 16,
    },
    closeIcon: {
        width: '100%',
        height: '100%',
    },
}));
export const ConnectToMeetingsTooltip = ({ open, onClose, anchorEl, children }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    // this width the button hides the text
    const medium = useMediaQuery('(max-width: 1200px) and (min-width: 600px)');
    const electronCaptionMode = useAppSelector(selectElectronCaptionMode);
    return (React.createElement(Tooltip, { open: open, title: React.createElement(React.Fragment, null,
            React.createElement(Typography, { className: classes.text }, t('connectToOnlineMeetings.popover')),
            React.createElement(IconButton, { "aria-label": "close", className: classes.closeButton, onClick: onClose },
                React.createElement(CloseIcon, { className: classes.closeIcon }))), arrow: true, classes: { tooltip: classes.tooltip, arrow: classes.arrow }, placement: "bottom-start", PopperProps: {
            anchorEl: anchorEl,
            style: {
                zIndex: 9999999,
                position: 'absolute',
                top: electronCaptionMode ? 50 : 'unset',
                left: electronCaptionMode ? 50 : 'unset',
            },
            modifiers: [
                { name: 'offset', options: { offset: electronCaptionMode ? [50, 0] : medium ? [0, 0] : [100, 0] } },
            ],
        }, children: children }));
};
