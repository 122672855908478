var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import AddLinkIcon from '@mui/icons-material/AddLink';
import CloseIcon from '@mui/icons-material/Close';
import { Button } from '@mui/material';
import { ClickAwayListener } from '@mui/material';
import Box from '@mui/material/Box';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import ScribeFlatImage from '../../../assets/icons/convov2-scribe-flat.svg';
import AvaLogoImage from '../../../assets/icons/new-ava-logo.svg';
import { useCopyInviteLink } from '../../hooks/useCopyInviteLink';
import { selectStatusHostAvaId, selectUniqueParticipants } from '../../selectors/legacy-conversation';
import { getScribeRemainingTime, selectAvaId } from '../../selectors/userProfile';
import { useAppSelector } from '../../store/store';
import { getSpeakerLabels, sortedSpeakers } from '../../utils/speakers';
import { ConvoV2ParticipantAvatar } from './ConvoV2ParticipantAvatar';
import { ConvoV2PrimaryButton } from './ConvoV2PrimaryButton';
const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        padding: '24px 8px',
        gap: 20,
    },
    top: {
        display: 'flex',
        flexDirection: 'column',
        padding: '0 16px',
        gap: 20,
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    headerTexts: {
        display: 'flex',
        alignItems: 'center',
        gap: 8,
        fontWeight: 600,
        fontSize: 20,
    },
    participantCount: {
        color: theme.palette.ava.text1,
    },
    closeButton: {
        minWidth: 0,
        padding: 0,
    },
    inviteRow: {
        display: 'flex',
        gap: 8,
    },
    inviteInput: {
        border: `1px solid ${theme.palette.ava.grey7}`,
        borderRadius: 8,
        padding: '8px 16px 8px 12px',
        fontSize: 14,
        fontWeight: 300,
    },
    primaryButton: {
        borderRadius: 8,
        fontSize: 16,
    },
    bottom: {
        display: 'flex',
        flexDirection: 'column',
        gap: 20,
    },
    scribeRow: {
        display: 'flex',
        width: '100%',
        alignItems: 'center',
        padding: '8px 16px',
        background: theme.palette.ava.scribe6,
        borderRadius: 12,
    },
    scribeIcon: {
        display: 'flex',
        width: 32,
        height: 32,
        marginRight: 8,
        border: `2px solid ${theme.palette.ava.scribe2}`,
        borderRadius: '50%',
        justifyContent: 'center',
        alignItems: 'center',
    },
    scribeTexts: {
        flexGrow: 2,
        lineHeight: '16px',
    },
    scribeTitle: {
        color: theme.palette.ava.scribe2,
        fontSize: 16,
        fontWeight: 400,
    },
    scribeHoursLeft: {
        color: theme.palette.ava.text1,
        fontSize: 14,
        fontWeight: 400,
    },
    scribeButton: {
        padding: '6px 12px',
        background: theme.palette.ava.scribe2,
        color: theme.palette.ava.white,
        borderRadius: 100,
        fontWeight: 600,
        fontSize: 16,
        lineHeight: '20px',
        '&:hover': {
            backgroundColor: theme.palette.ava.scribe2,
        },
    },
    particapantList: {
        display: 'flex',
        flexDirection: 'column',
        gap: 20,
        padding: '0 16px',
    },
    participantRow: {
        display: 'flex',
        alignItems: 'center',
        gap: 8,
    },
    participantAvatar: {
        border: 0,
    },
    participantTexts: {
        flexGrow: 2,
        lineHeight: '20px',
    },
    participantTitle: {
        fontWeight: 400,
        fontSize: 16,
    },
    participantHandle: {
        display: 'flex',
        alignItems: 'center',
        gap: 4,
        fontWeight: 400,
        fontSize: 14,
        color: theme.palette.ava.grey5,
    },
    inviteLinkButton: {
        margin: '0 16px',
    },
}));
export const ConvoV2ParticipantsCard = React.memo(({ onClose, className }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const { copyInviteLink } = useCopyInviteLink();
    const participants = useAppSelector(selectUniqueParticipants);
    const scribeHoursLeft = Math.floor(useAppSelector(getScribeRemainingTime) / 1000 / 60);
    const hostAvaId = useAppSelector(selectStatusHostAvaId);
    const userAvaId = useAppSelector(selectAvaId);
    const sortedParticipants = sortedSpeakers(participants, userAvaId, hostAvaId);
    const onCopyInviteLink = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        yield copyInviteLink();
        onClose();
    }), [copyInviteLink, onClose]);
    return (React.createElement(ClickAwayListener, { onClickAway: onClose },
        React.createElement(Box, { className: classNames(classes.root, className) },
            React.createElement(Box, { className: classes.top },
                React.createElement(Box, { className: classes.header },
                    React.createElement(Box, { className: classes.headerTexts },
                        t('convoV2.participants.title'),
                        React.createElement(Box, { className: classes.participantCount }, participants.length)),
                    React.createElement(Button, { className: classes.closeButton },
                        React.createElement(CloseIcon, null))),
                React.createElement(Box, { className: classes.inviteRow },
                    React.createElement("input", { className: classes.inviteInput, placeholder: t('convoV2.participants.invitePlaceholder') }),
                    React.createElement(ConvoV2PrimaryButton, { className: classes.primaryButton, disabled: true }, t('convoV2.participants.invite')))),
            React.createElement(Box, { className: classes.bottom },
                React.createElement(Box, { className: classes.scribeRow },
                    React.createElement(Box, { className: classes.scribeIcon },
                        React.createElement("img", { src: ScribeFlatImage })),
                    React.createElement(Box, { className: classes.scribeTexts },
                        React.createElement(Box, { className: classes.scribeTitle }, t('scribe.scribe')),
                        React.createElement(Box, { className: classes.scribeHoursLeft },
                            scribeHoursLeft === 0 && t('convoV2.time.availableHoursZero'),
                            scribeHoursLeft === 1 && t('convoV2.time.availableHoursOne'),
                            scribeHoursLeft > 1 && t('convoV2.time.availableHoursPlural', { count: scribeHoursLeft }))),
                    React.createElement(Button, { className: classes.scribeButton }, "Request")),
                React.createElement(Box, { className: classes.particapantList }, sortedParticipants.map((participant) => {
                    const labels = getSpeakerLabels(t, participant, userAvaId, hostAvaId);
                    return (React.createElement(Box, { key: participant.avaId, className: classes.participantRow },
                        React.createElement(ConvoV2ParticipantAvatar, { className: classes.participantAvatar, speaker: participant, size: 32, borderAroundSmiley: false }),
                        React.createElement(Box, { className: classes.participantTexts },
                            React.createElement(Box, { className: classes.participantTitle },
                                participant.userName,
                                labels.length > 0 && ` (${labels.join(', ')})`),
                            React.createElement(Box, { className: classes.participantHandle },
                                React.createElement("img", { src: AvaLogoImage }),
                                participant.avaName))));
                })),
                React.createElement(ConvoV2PrimaryButton, { className: classNames(classes.primaryButton, classes.inviteLinkButton), onClick: onCopyInviteLink },
                    React.createElement(AddLinkIcon, { width: 24, height: 24 }),
                    t('convoV2.participants.copyInviteLink'))))));
});
