import Box from '@mui/material/Box';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import React from 'react';
import PlaceholderImage from '../../../assets/images/convo-placeholder.svg';
import { selectHaveTranscripts } from '../../selectors/legacy-conversation';
import { useAppSelector } from '../../store/store';
const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        height: '100%',
        alignItems: 'center',
        justifyContent: 'center',
    },
}));
export const ConvoV2TranscriptPlaceholder = React.memo((props) => {
    const haveTranscripts = useAppSelector(selectHaveTranscripts);
    const classes = useStyles();
    if (haveTranscripts) {
        return null;
    }
    return (React.createElement(Box, { className: classNames(classes.root, props.className) },
        React.createElement("img", { src: PlaceholderImage })));
});
