import { createSelector } from 'reselect';
import { KARAOKE_COLOR } from '../store/slices/uiState';
import { selectAvailableMicrophones } from './audioV2';
import { selectAsrFinalById, selectHighLightedById, selectSpeakersMap, selectTranscript } from './legacy-conversation';
import { selectIsScribing } from './scribe';
import { selectElectronCaptionMode, selectKaraokeState } from './ui';
import { selectAvaId } from './userProfile';
export const selectCurrentUserSpeaker = createSelector([selectAvaId, selectTranscript, selectSpeakersMap], (avaId, transcript, speakers) => {
    var _a;
    if (!avaId)
        return;
    let speaker = speakers[avaId];
    const currentTranscriptAuthorSpeaker = (_a = speakers === null || speakers === void 0 ? void 0 : speakers[transcript === null || transcript === void 0 ? void 0 : transcript.author]) !== null && _a !== void 0 ? _a : undefined;
    // When a scribe/user splits a transcript the transcript.author is briefly 'Callee'
    // before it is corrected. We need to handle this brief moment so it doesn't crash the app.
    if (currentTranscriptAuthorSpeaker && !currentTranscriptAuthorSpeaker.isSoloDiaSpeakerCurrentUser) {
        // If is Solo-Dia and is marked as myself = true then use currentUser speaker object.
        speaker = currentTranscriptAuthorSpeaker;
    }
    return speaker;
});
export const selectMicOfTranscriptById = createSelector([selectAvailableMicrophones, selectTranscript], (availableMicrophones, transcript) => {
    if (!transcript || !availableMicrophones)
        return undefined;
    const { trackName } = transcript;
    return availableMicrophones.find((mic) => mic.name === trackName || mic.label === trackName);
});
export const selectIsInternalAudio = createSelector([selectTranscript, selectCurrentUserSpeaker, selectAvaId, selectMicOfTranscriptById], (transcript, currentSpeaker, avaId, micOfCurrentTranscript) => {
    var _a;
    if ((currentSpeaker === null || currentSpeaker === void 0 ? void 0 : currentSpeaker.avaId) === avaId &&
        (((_a = transcript === null || transcript === void 0 ? void 0 : transcript.trackName) === null || _a === void 0 ? void 0 : _a.startsWith('Ava Computer Audio')) || (micOfCurrentTranscript === null || micOfCurrentTranscript === void 0 ? void 0 : micOfCurrentTranscript.isInternal)))
        return true;
    else {
        return false;
    }
});
/**
 * This color ends up being the color for AvatarLogo, and SpeakerLabel
 * Also used as fallback/default colors for the transcript itself in certain cases like internal audio/etc
 */
export const selectColorForLogoAndSpeakerLabel = createSelector([
    (state, transcriptId, theme) => ({
        state,
        transcriptId,
        theme,
    }),
    selectAvaId,
    selectIsInternalAudio,
    selectCurrentUserSpeaker,
], ({ theme }, avaId, isInternalAudio, currentUserSpeaker) => {
    var _a, _b;
    let color = theme.palette.error.light;
    if (currentUserSpeaker === null || currentUserSpeaker === void 0 ? void 0 : currentUserSpeaker.theme) {
        const { theme: speakerTheme } = currentUserSpeaker;
        color = (_b = (_a = speakerTheme[`${theme.palette.mode}_theme`]) === null || _a === void 0 ? void 0 : _a.text_color) !== null && _b !== void 0 ? _b : color;
    }
    if (currentUserSpeaker && currentUserSpeaker.avaId === avaId) {
        color =
            theme.palette.mode === 'light'
                ? theme.palette.ava.navyGroup.convoText
                : theme.palette.ava.navyGroup.convoTextDark;
        if (isInternalAudio) {
            color = theme.palette.ava.green;
        }
    }
    //TODO check why this is here, copied from ScribeTranscript.tsx
    if (theme.palette.mode === 'dark' && color === '#000000') {
        color = '#FFFFFF';
    }
    return color;
});
export const selectCurrentCaptionsColor = createSelector([
    (state, id, theme, wordIndex) => ({ id, theme, wordIndex }),
    selectElectronCaptionMode,
    selectHighLightedById,
    selectAsrFinalById,
    selectIsScribing,
    selectSpeakersMap,
    selectColorForLogoAndSpeakerLabel,
    selectKaraokeState,
], ({ id, theme, wordIndex }, electronCaptionMode, highlighted, asrFinal, isScribing, speakers, color, karaokeState) => {
    if ((karaokeState === null || karaokeState === void 0 ? void 0 : karaokeState.transcriptId) === id && (karaokeState === null || karaokeState === void 0 ? void 0 : karaokeState.wordIndex) === wordIndex) {
        return KARAOKE_COLOR;
    }
    const singleSpeaker = Object.keys(speakers).length === 1;
    const scribeNotHighlightedFinalCaption = !highlighted && asrFinal && isScribing;
    const scribeNotHighlightedNotFinalCaption = !highlighted && isScribing && !asrFinal;
    if (electronCaptionMode || (singleSpeaker && theme.palette.mode === 'dark')) {
        return theme.palette.ava.white;
    }
    else if (scribeNotHighlightedNotFinalCaption) {
        if (theme.palette.mode === 'light')
            return theme.palette.ava.asrGrey2;
        else
            return theme.palette.ava.grey7;
    }
    else if (scribeNotHighlightedFinalCaption) {
        return color;
    }
    else {
        return color;
    }
});
