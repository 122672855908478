import ChevronDownIcon from '@mui/icons-material/KeyboardArrowDown';
import PauseIcon from '@mui/icons-material/Pause';
import { useTheme } from '@mui/material';
import { CircularProgress, Popover } from '@mui/material';
import Box from '@mui/material/Box';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import React, { useRef, useState } from 'react';
import { selectAudioV2Status } from '../../selectors/audioV2';
import { selectCanBeginRecording } from '../../selectors/combined';
import { RecordingStatus, startRecording, stopRecording } from '../../store/slices/audioV2';
import { useAppDispatch, useAppSelector } from '../../store/store';
import { ConvoV2AudioDeviceSelector } from './ConvoV2AudioDeviceSelector';
import { ConvoV2MicIcon } from './ConvoV2MicIcon';
const useStyles = makeStyles((theme) => ({
    root: {
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        height: 44,
        borderRadius: 24,
        border: '1px solid #E3E5E9',
        zIndex: 1,
    },
    toggleRecording: {
        position: 'absolute',
        display: 'flex',
        left: 0,
        top: '50%',
        transform: 'translateY(-50%)',
        zIndex: 2,
        alignItems: 'center',
        justifyContent: 'center',
        width: 44,
        height: 44,
        borderRadius: '50%',
        cursor: 'pointer',
    },
    audioIsRecording: {
        background: 'radial-gradient(120.92% 106.78% at 0.00% 0%, #50ACED 0%, rgba(134, 205, 255, 0.98) 99.99%, rgba(134, 190, 255, 0.98) 100%)',
    },
    audioIsRecordingIcon: {
        color: theme.palette.ava.white,
    },
    audioNotRecording: {
        background: '#F1F3F6',
        border: '1px solid #E3E5E9',
    },
    audioPendingSpinner: {
        color: theme.palette.ava.grey5,
    },
    toggleMicFiller: {
        width: 44,
    },
    chevronContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        width: 38,
        cursor: 'pointer',
    },
    chevron: {
        color: '#778290',
    },
}));
export const ConvoV2AudioControls = React.memo((props) => {
    const classes = useStyles();
    const theme = useTheme();
    const recordingStatus = useAppSelector(selectAudioV2Status);
    const canBeginRecording = useAppSelector(selectCanBeginRecording);
    const dispatch = useAppDispatch();
    const rootRef = useRef(null);
    const [deviceListOpen, setDeviceListOpen] = useState(false);
    const maybeStartRecording = () => {
        if (canBeginRecording) {
            dispatch(startRecording());
        }
    };
    return (React.createElement(Box, { className: classNames(classes.root, props.className), ref: rootRef },
        recordingStatus === RecordingStatus.RECORDING && (React.createElement(Box, { className: classNames(classes.toggleRecording, classes.audioIsRecording), onClick: () => dispatch(stopRecording()) },
            React.createElement(PauseIcon, { className: classes.audioIsRecordingIcon }))),
        recordingStatus === RecordingStatus.PENDING && (React.createElement(Box, { className: classNames(classes.toggleRecording, classes.audioNotRecording) },
            React.createElement(CircularProgress, { className: classes.audioPendingSpinner }))),
        recordingStatus === RecordingStatus.NOT_RECORDING && (React.createElement(Box, { className: classNames(classes.toggleRecording, classes.audioNotRecording), onClick: maybeStartRecording },
            React.createElement(ConvoV2MicIcon, { color: theme.palette.ava.grey8 }))),
        React.createElement(Box, { className: classes.toggleMicFiller }),
        React.createElement(Box, { className: classes.chevronContainer, onClick: () => setDeviceListOpen(true) },
            React.createElement(ChevronDownIcon, { className: classes.chevron })),
        rootRef.current && (React.createElement(Popover, { open: deviceListOpen, onClose: () => setDeviceListOpen(false), anchorEl: rootRef.current, anchorOrigin: { vertical: 'top', horizontal: 'center' }, transformOrigin: { vertical: 'bottom', horizontal: 'center' }, PaperProps: {
                style: {
                    marginTop: -28,
                    borderRadius: 12,
                    boxShadow: '0px 4px 8px 0px rgba(112, 144, 176, 0.20)',
                },
            } },
            React.createElement(ConvoV2AudioDeviceSelector, null)))));
});
