import CampaignIcon from '@mui/icons-material/Campaign';
import CloseIcon from '@mui/icons-material/Close';
import { Card, ClickAwayListener, IconButton, Popover, Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import RoundedButton from '../../components/Buttons/RoundedButton';
import { selectIsUsingTts } from '../../selectors/textToSpeech';
import { selectElectronCaptionMode } from '../../selectors/ui';
import { setIsUsingTts } from '../../store/slices/textToSpeech';
import { useAppDispatch } from '../../store/store';
import { useAppSelector } from '../../store/store';
const useStyles = makeStyles((theme) => ({
    button: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: (props) => (props.electronCaptionMode ? 32 : 56),
        width: (props) => (props.electronCaptionMode ? 32 : 56),
        color: (props) => props.isUsingTts && props.electronCaptionMode
            ? theme.palette.ava.blue2
            : !props.isUsingTts && !props.electronCaptionMode
                ? theme.palette.ava.deepBlue
                : 'white',
        backgroundColor: (props) => props.electronCaptionMode ? 'transparent' : !props.isUsingTts ? 'white' : theme.palette.ava.blue,
        '&:hover': {
            backgroundColor: (props) => !props.isUsingTts && !props.electronCaptionMode ? theme.palette.ava.blue4 : theme.palette.ava.deepBlue,
        },
        boxShadow: (props) => !props.electronCaptionMode
            ? '0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)'
            : 'none',
        position: (props) => (props.electronCaptionMode ? 'relative' : 'absolute'),
        bottom: (props) => (!props.electronCaptionMode ? 30 : 0),
        right: (props) => (!props.electronCaptionMode ? 85 : 0),
        zIndex: 101,
    },
    popover: {
        marginLeft: 33,
        borderRadius: '20px',
    },
    popoverContainer: {
        width: 340,
        height: 147,
        display: 'flex',
        flexDirection: 'column',
        padding: '20px',
        alignItems: 'center',
        justifyContent: 'center',
        background: theme.palette.ava.darkBg2,
        borderRadius: '20px',
    },
    titleContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    close: {
        position: 'absolute',
        right: -5,
    },
    choicesContainer: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
    },
    paragraph: {
        textAlign: 'center',
        marginTop: -15,
    },
    choices: {
        color: theme.palette.ava.navy,
        background: 'white',
        border: '1px solid white',
        '&:hover': {
            backgroundColor: 'transparent',
            color: 'white',
        },
        fontSize: (props) => (props.lang === 'fr' ? 12 : 14),
        width: 120,
        height: 32,
        padding: '8px 15px',
    },
    tooltip: {
        padding: '2.5px 8px',
        backgroundColor: theme.palette.ava.grey4,
        borderRadius: 4,
        fontSize: 14,
        textAlign: 'center',
    },
    sendToolTipBottom: {
        position: 'relative',
        top: (props) => (props.electronCaptionMode ? 75 : 10),
        right: 15,
    },
}));
function V1TtsButton() {
    const [open, setOpen] = useState(false);
    const parentRef = useRef(null);
    const { t, i18n } = useTranslation();
    const dispatch = useAppDispatch();
    const isUsingTts = useAppSelector(selectIsUsingTts);
    const electronCaptionMode = useAppSelector(selectElectronCaptionMode);
    const [isButtonHovered, setIsButtonHovered] = useState(false);
    const classes = useStyles({ isUsingTts, lang: i18n.language, electronCaptionMode });
    const handleButtonClick = () => {
        dispatch(setIsUsingTts(!isUsingTts));
    };
    const handleCancel = () => {
        setOpen(false);
        dispatch(setIsUsingTts(false));
    };
    const handlePickVoice = () => {
        setOpen(false);
        dispatch(setIsUsingTts(true));
    };
    return (React.createElement("div", { style: { marginRight: 10 } },
        React.createElement("div", { ref: parentRef }, open ? (React.createElement(Popover, { anchorEl: parentRef.current, open: open, anchorOrigin: {
                vertical: 'top',
                horizontal: 'left',
            }, transformOrigin: {
                vertical: 'bottom',
                horizontal: 'right',
            }, PaperProps: { className: classes.popover } },
            React.createElement(ClickAwayListener, { onClickAway: () => handleCancel() },
                React.createElement(Card, { className: classes.popoverContainer },
                    React.createElement("div", { style: { marginTop: -20 } },
                        React.createElement("span", { className: classes.titleContainer },
                            React.createElement("h2", null, t('ccMode.keyboard.ttsChoice')),
                            React.createElement(IconButton, { className: classes.close, onClick: () => handleCancel() },
                                React.createElement(CloseIcon, null))),
                        React.createElement("p", { className: classes.paragraph }, t('ccMode.keyboard.ttsChoiceDescription'))),
                    React.createElement("div", { className: classes.choicesContainer },
                        React.createElement(RoundedButton, { className: classes.choices, onClick: () => handlePickVoice() }, t('ccMode.keyboard.maleVoice')),
                        React.createElement(RoundedButton, { style: { marginLeft: 8 }, className: classes.choices, onClick: () => handlePickVoice() }, t('ccMode.keyboard.femaleVoice'))))))) : null),
        React.createElement(Tooltip, { open: isButtonHovered && isUsingTts, classes: { tooltip: classes.tooltip, tooltipPlacementBottom: classes.sendToolTipBottom }, title: t('ccMode.keyboard.tooltip.tts'), placement: "bottom", disableInteractive: true, onMouseEnter: () => setIsButtonHovered(true), onMouseLeave: () => setIsButtonHovered(false) },
            React.createElement(IconButton, { className: classes.button, onClick: handleButtonClick },
                React.createElement(CampaignIcon, null)))));
}
export default V1TtsButton;
