import { useCallback } from 'react';
import { useNotifications } from './useNotifications';
export const useSoloDiaNotifications = () => {
    const { notify } = useNotifications();
    const notifySoloDiaInit = useCallback((additionalOptions) => {
        const text = 'soloDia';
        const options = Object.assign({ variant: 'soloDia' }, additionalOptions);
        notify(text, options);
    }, [notify]);
    const notifySoloDiaSuccess = useCallback((additionalOptions) => {
        const text = 'soloDiaSuccess';
        const options = Object.assign({ variant: 'soloDiaSuccess' }, additionalOptions);
        notify(text, options);
    }, [notify]);
    return {
        notifySoloDiaInit,
        notifySoloDiaSuccess,
    };
};
