import { Button, Divider, InputLabel } from '@mui/material';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import React, { useEffect, useState } from 'react';
import ReactCountryFlag from 'react-country-flag';
import { useTranslation } from 'react-i18next';
import autoTranslateLangs from '../../../assets/auto_translate_langs.json';
import { selectGuestLanguage } from '../../selectors/avaTranslate';
import { selectIsSideBarOpen } from '../../selectors/ui';
import { useAppSelector } from '../../store/store';
import { canShowFlagEmoji } from '../../utils/platform';
const FAVORITE_LANGUAGES = ['nl-NL', 'it-IT', 'es-ES', 'en-US', 'en-GB', 'de-DE'];
export function AutoTranslateGuestLanguageSelector({ open, initialLanguageCode, onLanguageChange }) {
    const [value, setValue] = useState(initialLanguageCode || '');
    const theme = useTheme();
    const sidebarOpen = useAppSelector(selectIsSideBarOpen);
    const guestLang = useAppSelector(selectGuestLanguage);
    const { t } = useTranslation();
    const langList = Object.values(autoTranslateLangs).sort((langA, langB) => langA.nativeName.localeCompare(langB.nativeName));
    const confirm = () => {
        onLanguageChange(value);
    };
    useEffect(() => {
        if (!initialLanguageCode)
            return;
        setValue(initialLanguageCode);
    }, [initialLanguageCode]);
    const langListAboveDivider = [];
    if (value) {
        langListAboveDivider.push(autoTranslateLangs[value]);
    }
    langListAboveDivider.push(...FAVORITE_LANGUAGES.filter((langCode) => langCode !== value).map((langCode) => autoTranslateLangs[langCode]));
    const langListBelowDivider = [];
    langListBelowDivider.push(...langList.filter((lang) => lang.languageCode !== value && !FAVORITE_LANGUAGES.includes(lang.languageCode)));
    return (React.createElement(Box, { sx: {
            position: 'fixed',
            bottom: 0,
            left: sidebarOpen ? 269 : 0,
            zIndex: 10000,
            marginBottom: '0px !important',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'stretch',
            flexShrink: 0,
            borderTopLeftRadius: '36px',
            borderTopRightRadius: '36px',
            backgroundColor: theme.palette.secondary[theme.palette.mode],
            boxShadow: '2px 2px 10px 0px rgba(0,0,0,0.1)',
            padding: 3,
            overflow: 'hidden',
            transition: 'transform 0.3s ease-in-out',
            transform: open ? 'translateY(0%)' : 'translateY(100%)',
            width: `calc(calc(var(--vw, 1vw) * 100) - ${sidebarOpen ? 269 : 0}px)}`,
        } },
        React.createElement(Typography, { variant: 'h5', fontWeight: 600, sx: { textAlign: 'center', marginBottom: 2 } }, t('sidebar.chooseLanguage')),
        React.createElement(Box, { sx: {
                flexGrow: 1,
            } },
            React.createElement(InputLabel, { sx: { marginBottom: 1 } }, t('autoTranslate.availableLanguages')),
            React.createElement(Select, { sx: {
                    borderRadius: '24px',
                }, size: "small", fullWidth: true, value: value, displayEmpty: true, onChange: (e) => {
                    setValue(e.target.value);
                } },
                React.createElement(MenuItem, { disabled: true, key: '', value: '', sx: { justifyContent: 'center' } },
                    React.createElement(React.Fragment, null,
                        "\uD83C\uDF10 ",
                        t('autoTranslate.languageList'))),
                langListAboveDivider.map((lang) => (React.createElement(MenuItem, { key: lang.languageCode, value: lang.languageCode, sx: { justifyContent: 'center' } },
                    React.createElement(ReactCountryFlag, { style: { marginRight: 3 }, svg: !canShowFlagEmoji(), countryCode: lang.flagCode }),
                    lang.nativeName))),
                React.createElement(Divider, null),
                langListBelowDivider.map((lang) => (React.createElement(MenuItem, { key: lang.languageCode, value: lang.languageCode, sx: { justifyContent: 'center' } },
                    React.createElement(ReactCountryFlag, { style: { marginRight: 3 }, svg: !canShowFlagEmoji(), countryCode: lang.flagCode }),
                    lang.nativeName))))),
        React.createElement(Button, { variant: 'contained', onClick: confirm, disabled: !value, sx: {
                alignSelf: 'center',
                marginTop: 2,
                paddingX: 2,
                borderRadius: '24px',
            } }, t('autoTranslate.confirm'))));
}
