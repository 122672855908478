var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { batch } from 'react-redux';
import { v4 as uuid } from 'uuid';
import { selectIsInConversation } from '../../selectors/conversation';
import { selectAvaId } from '../../selectors/userProfile';
import { selectV1Websocket } from '../../selectors/v1Session';
import { maybeRestartRecording } from '../../store/slices/audioV2';
import { setCCMode } from '../../store/slices/ccMode';
import { updateConversationEnded, updateIsInConversation } from '../../store/slices/conversation';
import { initiateConversationEndSequence } from '../../store/slices/conversationEnd';
import { setScribing } from '../../store/slices/scribe';
import { setLoading } from '../../store/slices/uiState';
import { setConferenceCallRequested, setV1Token } from '../../store/slices/v1Session';
import { navigateToTranscriptURLIfNeeded } from '../../utils/conversation';
import { getSearchValueFromString, setSearchValue } from '../../utils/http';
import { updateRoomId } from '../../utils/log';
import { isConversationEnded, isDefaultRoomId, sendResetRoomIdMessage, sendRoomStateUpdateMessage, sendUsePaidAsrUpdateMessage, } from '../../utils/ws-v1';
import { fetchRemainingCredits } from '../session';
export const setLangSelected = (lang) => {
    return {
        type: 'SET_SPEECH_LANG',
        lang,
    };
};
export const clearScribeConversationState = () => ({
    type: 'CLEAR_SCRIBE_CONVERSATION',
});
// Returns [batchDispatchFn, cancelFn]
const makeBatchDispatcher = (dispatch) => {
    const actionsToDispatch = [];
    const batchedDispatch = (action) => {
        actionsToDispatch.push(action);
    };
    const dispatchAllActions = () => {
        batch(() => {
            actionsToDispatch.forEach(dispatch);
            actionsToDispatch.splice(0, actionsToDispatch.length);
        });
    };
    const interval = setInterval(dispatchAllActions, 250);
    return [
        batchedDispatch,
        () => {
            dispatchAllActions();
            clearInterval(interval);
        },
    ];
};
export const handleNewRoomStatus = (status) => {
    return (dispatch, getState) => {
        var _a, _b;
        const state = getState();
        const avaId = selectAvaId(state);
        const isInConversation = selectIsInConversation(state);
        const ws = selectV1Websocket(state);
        updateRoomId(status.id);
        dispatch({
            type: 'ROOM_STATUS_UPDATE',
            status,
        });
        const defaultRoomId = !(status === null || status === void 0 ? void 0 : status.id) || isDefaultRoomId(status.id);
        const isEnded = isConversationEnded(status, avaId);
        if (!defaultRoomId && !isInConversation) {
            // The conversation just started
            if (ws)
                sendRoomStateUpdateMessage(ws, { value: 'ongoing' });
            if (ws)
                sendUsePaidAsrUpdateMessage(ws, true);
        }
        dispatch(updateIsInConversation(!!status.id && !defaultRoomId && !status.past));
        dispatch(updateConversationEnded(isEnded && isInConversation));
        if (((_a = status === null || status === void 0 ? void 0 : status.state) === null || _a === void 0 ? void 0 : _a.value) === 'ended' && (status === null || status === void 0 ? void 0 : status.reason) === 'room-ended-due-to-convo-restricted-session') {
            dispatch(initiateConversationEndSequence());
        }
        if (defaultRoomId) {
            dispatch(setV1Token(undefined));
            dispatch(setScribing(false));
        }
        if (!defaultRoomId) {
            const rwLink = (status.links || {}).rw;
            const token = getSearchValueFromString(rwLink.split('?')[1], 'token', undefined);
            if (token) {
                dispatch(setV1Token(token));
            }
            const endpoint = getSearchValueFromString(rwLink.split('?')[1], 'endpoint', undefined);
            if (endpoint) {
                setSearchValue(window, 'endpoint', endpoint);
            }
            // Check if current user is scribe
            if (status &&
                status.participants &&
                state &&
                ((_b = state.userProfile.parse) === null || _b === void 0 ? void 0 : _b.avaId) &&
                status.participants.some(({ scribe, avaId }) => { var _a; return scribe && avaId === ((_a = state.userProfile.parse) === null || _a === void 0 ? void 0 : _a.avaId); })) {
                dispatch(setScribing(true));
            }
        }
    };
};
export const startConversationReadClient = (ws, dispatch, getState) => {
    let loadedAudio;
    const [batchedDispatch, cancelBatchDispatch] = makeBatchDispatcher(dispatch);
    const currentConversationListener = (event) => {
        const message = JSON.parse(event.data);
        if (message.type === 'transcript-edit') {
            const { mutation, origin, transcriptId, speakerId } = message.data;
            batchedDispatch({
                type: 'RECEIVED_SCRIBE_CONVERSATION_TRANSCRIPT_MUTATION',
                mutation,
                origin,
                transcriptId,
                speakerId,
            });
        }
        else if (message.type === 'audio' && !loadedAudio) {
            loadedAudio = true;
        }
        else if (message.type === 'room-status-update' || message.type === 'room-status') {
            batchedDispatch(handleNewRoomStatus(message));
        }
        else if (message.type === 'reset-recorder') {
            dispatch(maybeRestartRecording());
        }
    };
    ws.addEventListener('message', currentConversationListener);
    ws.addEventListener('close', () => {
        cancelBatchDispatch();
    });
};
export const switchLang = (lang) => ({ type: 'SWITCH_LANG', lang });
export const createNewConversation = (options) => (dispatch, getState) => __awaiter(void 0, void 0, void 0, function* () {
    const ws = getState().v1Session.v1Socket;
    dispatch(setLoading(true));
    if (!ws) {
        dispatch(setLoading(false));
        return;
    }
    navigateToTranscriptURLIfNeeded();
    dispatch(setCCMode('conversation'));
    const cache = { listener: undefined, closeListener: undefined };
    cache.closeListener = () => {
        if (cache.listener)
            ws.removeEventListener('message', cache.listener);
        if (cache.closeListener)
            ws.removeEventListener('close', cache.closeListener);
        dispatch(setLoading(false));
    };
    ws.addEventListener('close', cache.closeListener);
    const { parse } = getState().userProfile;
    // Purposefully ignoring promise, as we only care about side effects.
    fetchRemainingCredits(parse === null || parse === void 0 ? void 0 : parse.avaId)(dispatch);
    const roomId = (options && options.roomId) || `${parse === null || parse === void 0 ? void 0 : parse.avaId}_${uuid()}`;
    const inConferenceCall = options && options.inConferenceCall;
    dispatch(setConferenceCallRequested(!!inConferenceCall));
    cache.listener = (message) => {
        const data = JSON.parse(message.data);
        if (data.type === 'room-status' && data.id === roomId) {
            ws.removeEventListener('message', cache.listener);
            ws.removeEventListener('close', cache.closeListener);
        }
    };
    ws.addEventListener('message', cache.listener);
    sendResetRoomIdMessage(ws, roomId);
});
if (window.isElectron) {
    window.electronIPC.onCreateNewConversation(() => __awaiter(void 0, void 0, void 0, function* () {
        window.store.dispatch(createNewConversation());
    }));
    window.electronIPC.onEndConversation(() => {
        window.store.dispatch(initiateConversationEndSequence());
    });
}
