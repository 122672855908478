var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Button, CircularProgress, Fab, FormControl, FormControlLabel, FormHelperText, FormLabel, Grid, Radio, RadioGroup, TextField, Typography, } from '@mui/material';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Link, Navigate, Outlet, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import addOrg from '../../../assets/icons/add_org.svg';
import always from '../../../assets/icons/always.svg';
import deafASL from '../../../assets/icons/deaf_asl.svg';
import deafHOH from '../../../assets/icons/deaf_or_hoh.svg';
import eduOrg from '../../../assets/icons/edu.svg';
import eventOrg from '../../../assets/icons/event.svg';
import hearing from '../../../assets/icons/hearing.svg';
import hrOrg from '../../../assets/icons/hr.svg';
import notAlways from '../../../assets/icons/not_always.svg';
import AvaLanding from '../../../assets/images/ava_landing.svg';
import Event from '../../../assets/images/event.svg';
import loginLineDown from '../../../assets/images/login_line_down.svg';
import loginLineUp from '../../../assets/images/login_line_up.svg';
import avaLogo from '../../../assets/images/logo.png';
import School from '../../../assets/images/school.svg';
import Video from '../../../assets/images/video.svg';
import Work from '../../../assets/images/work-laptop.svg';
import { createNewConversation } from '../../actions';
import Loading from '../../components/Loading';
import OpenOrDownloadAvaAppComponent from '../../components/OpenOrDownloadAvaAppComponent';
import OrganizationsSearchResults from '../../components/OrganizationSearchResults';
import PhraseMarkdownText from '../../components/PhraseMarkdownText';
import { analytics } from '../../firebase';
import { selectFirebaseUser, selectIsTextToSpeechV2 } from '../../selectors/auth';
import { selectDisplayedItemsCount } from '../../selectors/textToSpeech';
import * as avaApi from '../../services/api/ava';
import * as saasApi from '../../services/api/saas';
import { triggerOrgTrial } from '../../services/api/subscription';
import LocalStorage, { STORAGE_KEYS } from '../../services/localStorage';
import { updateHearingProfile, updateUserName } from '../../store/slices/userProfile';
import { useAppSelector } from '../../store/store';
import { getOS } from '../../utils';
import * as segment from '../../utils/segment';
import { HEARING_PROFILES, VOICING_PROFILES } from '../../utils/user';
import { TtsGenderFab } from '../textToSpeech/TtsGenderFab';
import { TtsVoiceFab } from '../textToSpeech/TtsVoiceButton';
const useStyles = makeStyles((theme) => ({
    outerWrap: {
        width: '100%',
        background: theme.palette.ava.transparent.blue.light,
        display: 'flex',
        justifyContent: 'center',
        // windows only
        ['@media (min-width:701px) and (max-height: 600px)']: {
            minHeight: '120vh',
            marginBottom: (props) => (props.isOnboardingStep ? 'auto' : 0),
            overflowY: (props) => (props.tts ? 'scroll' : 'initial'),
        },
        // mobile or windows
        ['@media (max-width: 700px) or (max-height: 600px)']: {
            overflowY: 'scroll',
        },
        overflowY: (props) => (props.orgType ? 'scroll' : 'initial'),
        backgroundImage: (props) => (props.appBridge ? `url(${loginLineUp}), url(${loginLineDown})` : 'none'),
        backgroundPosition: 'top left, right',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '60% 60%',
        marginBottom: (props) => (props.voiceSelection && props.displayedItemsCount > 2 ? 'auto' : 0),
    },
    logo: {
        maxWidth: 121,
        maxHeight: 40,
        marginTop: 40,
    },
    hearingProfileContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        paddingTop: 40,
        width: 'auto',
        gap: (props) => (props.appBridge ? 16 : 40),
        marginBottom: (props) => (props.tts || props.orgType || props.voiceSelection ? 40 : 0),
    },
    hearingProfileCardContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: 40,
        padding: '32px 40px',
        backgroundColor: theme.palette.ava.white,
        borderRadius: 20,
        width: 700,
        ['@media (max-width: 700px)']: {
            width: '100vw',
            maxWidth: 350,
            maxHeight: 'none',
            height: 'auto',
            padding: '32px 20px',
        },
    },
    hearingProfileCard: {
        display: 'flex',
        flexDirection: 'column',
        gap: 40,
    },
    voicSelectionContainer: {
        maxHeight: 914,
    },
    grid: {
        maxWidth: 700,
        minHeight: 'max-content',
        justifyContent: 'center',
        alignItems: 'center',
    },
    gridItem: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    gridItemContainer: {
        width: '50%',
        ['@media (max-width: 700px)']: {
            width: '100%',
        },
    },
    maxHeight: {
        maxHeight: 'none',
    },
    topContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: 'var(--8, 8px)',
        width: '100%',
        alignItems: 'center',
        alignSelf: 'stretch',
    },
    title: {
        fontWeight: 700,
        color: theme.palette.ava.deepBlue,
        width: '100%',
        fontSize: '2.45em',
        ['@media (max-width: 700px)']: {
            fontSize: '2em',
        },
    },
    smallerTitle: {
        fontSize: '1.45em',
    },
    textUnderTitle: {
        color: theme.palette.ava.text1,
        fontSize: '1.45em',
        fontWeight: 400,
        width: '95%',
        ['@media (max-width: 700px)']: {
            fontSize: '1em',
        },
    },
    fullWidth: {
        width: '100%',
    },
    orgTypeOptionsWrap: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        marginTop: theme.spacing(4),
        width: '100%',
    },
    orgTypeButton: {
        '&:hover': {
            backgroundColor: theme.palette.action.hover,
            textDecoration: 'none',
        },
        '&:focus': {
            outline: 'none',
        },
        [theme.breakpoints.down('md')]: {
            '& h6': {
                fontSize: '1rem',
            },
        },
        background: 'transparent',
        border: `2px solid ${theme.palette.border}`,
        borderRadius: 12,
        flexGrow: 0,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        WebkitAppearance: 'none',
        maxHeight: 244,
    },
    orgTypeIllustration: {
        width: 'auto',
        maxWidth: 216,
        maxHeight: 143,
    },
    orgInfoOptionsWrap: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        margin: '0 auto',
        marginTop: theme.spacing(2),
        maxWidth: 420,
        width: '100%',
    },
    formControl: {
        marginBottom: theme.spacing(2),
    },
    formLabel: {
        fontSize: '1em',
        marginBottom: 8,
        zIndex: 1,
    },
    helperText: {
        marginLeft: 14,
    },
    checkboxHelperText: {
        lineHeight: 3,
    },
    orgTypeLabel: {
        textTransform: 'capitalize',
    },
    noDecorationLink: {
        '&:hover': {
            textDecoration: 'none',
        },
    },
    button: {
        boxShadow: 'none',
        fontWeight: 600,
        marginTop: theme.spacing(2),
    },
    centerText: {
        textAlign: 'center',
    },
    fab: {
        backgroundColor: 'white',
        height: 80,
        padding: '16px 20px',
        gap: 20,
        boxShadow: '0px 4px 8px 0px rgba(112, 144, 176, 0.20)',
        borderRadius: 16,
        display: 'flex',
        justifyContent: 'flex-start',
        width: 400,
        ['@media (max-width: 700px)']: {
            width: 'auto',
        },
        '&:hover': {
            background: theme.palette.ava.navy5,
        },
    },
    voices: {
        boxShadow: 'none',
    },
    fabBox: {
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: 180,
        width: 170,
        gap: 16,
    },
    fabBoxOrg: {
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: 'auto',
        maxWidth: 284,
        maxHeight: 244,
        border: 'none',
    },
    fabText: {
        fontSize: 18,
        color: theme.palette.ava.navy,
        fontWeight: 400,
        gap: 16,
        textAlign: 'left',
        textTransform: 'none',
        width: '55%',
        ['@media (max-width: 700px)']: {
            fontSize: '1em',
        },
    },
    fabBoxText: {
        textAlign: 'center',
        fontSize: 20,
        width: '100%',
        display: 'flex',
        lineHeight: 'normal',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        color: 'black',
        fontWeight: 400,
        ['@media (min-width: 701px)']: {
            // width: 147,
            height: 72,
        },
    },
    fabContent: {
        display: 'flex',
        alignItems: 'center',
        gap: 16,
        width: 241,
    },
    later: {
        color: theme.palette.ava.blue2,
        maxWidth: 87,
        padding: 0,
        margin: 0,
        fontSize: 16,
        fontWeight: 600,
    },
    laterContainer: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
    },
    fabContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        alignSelf: 'stretch',
        gap: 16,
    },
    fabContainerVoiceSelection: {
        gap: 'var(--12, 12px)',
    },
    fabBoxContainer: {
        flexDirection: 'row',
        justifyContent: 'center',
        gap: 32,
        ['@media (max-width: 700px)']: {
            flexDirection: 'column',
        },
    },
    orgaSearchProgress: {
        position: 'absolute',
        width: 20,
        height: 20,
        right: 17,
        top: 17,
        color: theme.palette.ava.blue,
    },
    searchFieldWithPadding: {
        paddingLeft: 30,
    },
    searchResultTypePicto: {
        position: 'absolute',
        left: 9,
        top: 18,
        width: 25,
    },
    loginImageContainer: {
        width: '100vw',
        ['@media (max-width: 700px)']: {
            height: 350,
            marginTop: '15%',
        },
        ['@media (min-width: 701px)']: {
            height: 416,
            marginTop: 100,
        },
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
    },
    illustration: {
        height: '100%',
        width: '100%',
        maxWidth: 606,
    },
    maybeLater: {
        color: theme.palette.ava.text1,
        fontWeight: 600,
        fontSize: 18,
        border: 'none',
        fontStyle: 'normal',
        textTransform: 'none',
        backgroundColor: 'transparent',
        outline: 'none',
    },
    bigButton: {
        padding: '18px 24px',
        borderRadius: 100,
        fontSize: 18,
        fontWeight: 600,
        width: '100%',
        maxHeight: 56,
    },
    lowerContainerAppBridge: {
        display: 'flex',
        flexDirection: 'column',
        width: 344,
        justifyContent: 'center',
        alignItems: 'center',
        gap: 24,
    },
}));
const Wrapper = () => {
    const { pathname } = useLocation();
    const displayedItemsCount = useAppSelector(selectDisplayedItemsCount);
    const tts = pathname.includes('gender-selection');
    const orgType = pathname.includes('org-type');
    const appBridge = pathname.includes('app-bridge');
    const voiceSelection = pathname.includes('voice-selection');
    const isOnboardingStep = tts || orgType || appBridge || voiceSelection;
    const classes = useStyles({
        tts,
        orgType,
        appBridge,
        voiceSelection,
        displayedItemsCount,
        isOnboardingStep,
    });
    return (React.createElement("div", { className: classes.outerWrap },
        React.createElement("section", { className: classes.hearingProfileContainer },
            !pathname.includes('app-bridge') && React.createElement("img", { className: classes.logo, src: avaLogo, alt: "Ava Logo" }),
            React.createElement(Outlet, null))));
};
const ScribeLoginProfiling = () => {
    const { t, i18n: { language }, } = useTranslation();
    const firebaseUser = useAppSelector(selectFirebaseUser);
    const dispatch = useDispatch();
    const currentOrganizationSearch = useRef(null);
    const [orgType, setOrgType] = useState('hr');
    const [orgName, setOrgName] = useState(undefined);
    const [isAdmin, setIsAdmin] = useState('');
    const [isAdminRequiredError, setIsAdminRequiredError] = useState(false);
    const [orgNameRequiredError, setOrgNameRequiredError] = useState(false);
    const [orgId, setOrgId] = useState(undefined);
    const [inputOrgName, setInputOrgName] = useState('');
    const [loading, setLoading] = useState(false);
    const [hearingProfile, setHearingProfile] = useState(undefined);
    const [organizationsSearchResults, setOrganizationsSearchResults] = useState(null);
    const [currentlySearchingOrganizations, setCurrentlySearchingOrganizations] = useState(false);
    const [openOrDownloadAvaApp, setOpenOrDownloadAvaApp] = useState(false);
    const [orgInputFocused, setOrgInputFocused] = useState(false);
    const [resError, setResError] = useState('');
    const navigate = useNavigate();
    const location = useLocation();
    const classes = useStyles({ orgType: location.pathname.includes('org-type') });
    const isTextToSpeechV2 = useAppSelector(selectIsTextToSpeechV2);
    const submitUserName = () => {
        var _a;
        setLoading(true);
        // We are issuing the request, but in case it fails, we do not stop the login
        // flow to make it faster. In case it fails - the user's default name will
        // be well formatted part before @ of their email, which is not bad.
        const name = (_a = localStorage.getItem('onboarding_name_chosen')) !== null && _a !== void 0 ? _a : '';
        dispatch(updateUserName(name)).then(() => {
            segment.track('Updated Name', { Name: name });
            segment.identify({ name });
        });
        localStorage.removeItem('onboarding_name_chosen');
        const locationArray = location.pathname.split('/');
        if (locationArray[locationArray.length - 1] === 'user-name-subscribed') {
            // In the time of writing this means that that the user already
            // has the hearing-profile setup.
            if (!window.isElectron) {
                navigate(`/${locationArray[1]}/onboarding/profile/app-bridge`);
            }
            else if (location.pathname.split('/')[1] === 'web') {
                createConverstionAndRedirectToTranscript();
            }
        }
        else if (locationArray[1] === 'web') {
            // This is the path taken when the user logs in for the first time.
            navigate('/web/onboarding/profile/hearing-profile');
        }
        setLoading(false);
    };
    useEffect(() => {
        submitUserName();
    }, []);
    const createConverstionAndRedirectToTranscript = () => __awaiter(void 0, void 0, void 0, function* () {
        yield createNewConversation()(dispatch, window.store.getState);
    });
    const patchProfile = (profile) => {
        dispatch(updateHearingProfile(profile));
    };
    const setHearingProfileFun = (hearingProfile) => {
        segment.track('Hearing Profiled', {
            'Hearing Profile': hearingProfile,
        });
        segment.identify({
            'Hearing Profile': hearingProfile,
        });
        setHearingProfile(hearingProfile);
        const locationArray = location.pathname.split('/');
        if (hearingProfile === HEARING_PROFILES.HEARING) {
            navigate(`/${locationArray[1]}/onboarding/profile/org-type`);
            patchProfile(2);
        }
        else {
            navigate(`/${locationArray[1]}/onboarding/profile/voicing-profile`);
        }
    };
    const setVoicingProfile = (voicingProfile) => {
        segment.track('Voice Checked', {
            Voice: voicingProfile,
        });
        segment.identify({
            Voice: voicingProfile,
        });
        const locationArray = location.pathname.split('/');
        if (voicingProfile === VOICING_PROFILES.NOT_VOICING && isTextToSpeechV2) {
            navigate(`/${locationArray[1]}/onboarding/profile/gender-selection`);
        }
        else {
            navigate(`/${locationArray[1]}/onboarding/profile/org-type`);
        }
        if (hearingProfile === HEARING_PROFILES.HARD_OF_HEARING) {
            if (voicingProfile === VOICING_PROFILES.NOT_VOICING) {
                patchProfile(3);
            }
            else {
                patchProfile(1);
            }
        }
        else if (hearingProfile === HEARING_PROFILES.DEAF) {
            if (voicingProfile === VOICING_PROFILES.NOT_VOICING) {
                patchProfile(4);
            }
            else {
                patchProfile(0);
            }
        }
    };
    const setOrgTypeFun = (orgType) => {
        if (orgType === 'video') {
            segment.track('Role Profiled', { Role: 'user' });
            segment.identify({ Role: 'user' });
            if (!window.isElectron) {
                const locationArray = location.pathname.split('/');
                navigate(`/${locationArray[1]}/onboarding/profile/app-bridge`);
            }
            else if (location.pathname.split('/')[1] === 'web') {
                createConverstionAndRedirectToTranscript();
            }
        }
        else {
            setOrgType(orgType);
        }
        const market = orgType === 'video' ? 'ind' : orgType;
        segment.track('Market Profiled', { Market: market });
        segment.identify({ Market: market });
    };
    const setIsAdminFun = (e) => {
        setIsAdmin(e.target.value);
        setIsAdminRequiredError(false);
        setResError('');
    };
    const fetchOrganizationsByOrgName = (orgName) => __awaiter(void 0, void 0, void 0, function* () {
        if (!currentlySearchingOrganizations)
            return;
        if (currentOrganizationSearch.current !== orgName) {
            // Because there is some delay, we may get answer from server
            // for something else than what is currently typed. We ignore it
            return;
        }
        const response = yield avaApi.organizations.findOrganizations({
            firebaseAuthUID: firebaseUser.uid,
            orgName,
            orgType,
        });
        const { result } = response.data;
        const { resultFromParse, resultFromGoogle } = result || {};
        // We keep 3 results, parse first
        const organizations = (resultFromParse || []).slice(0, 3).map((r) => ({
            name: r.name,
            id: r.objectId,
        }));
        if (organizations.length < 3) {
            const googleOrgs = ((resultFromGoogle === null || resultFromGoogle === void 0 ? void 0 : resultFromGoogle.results) || []).slice(0, 3 - organizations.length).map((r) => ({
                name: r.name,
                address: r.formatted_address,
                id: r.place_id,
            }));
            organizations.push(...googleOrgs);
        }
        setOrganizationsSearchResults(organizations);
        setCurrentlySearchingOrganizations(false);
    });
    const findOrganizationsByOrgName = _.debounce(fetchOrganizationsByOrgName, 500);
    const setOrgNameFun = (e) => {
        setOrgName(undefined);
        setOrgId(undefined);
        setInputOrgName(e.target.value);
        setOrgNameRequiredError(false);
        setResError('');
        if (e.target.value) {
            setCurrentlySearchingOrganizations(true);
            currentOrganizationSearch.current = e.target.value;
            findOrganizationsByOrgName(e.target.value);
        }
        else {
            currentOrganizationSearch.current = null;
            setCurrentlySearchingOrganizations(false);
            setOrganizationsSearchResults(null);
        }
    };
    const submit = (e) => __awaiter(void 0, void 0, void 0, function* () {
        e.preventDefault();
        const userRole = isAdmin === 'yes' ? 'admin' : 'user';
        if (!isAdmin || !orgName) {
            setIsAdminRequiredError(!isAdmin);
            setOrgNameRequiredError(!orgName);
            return;
        }
        segment.track('Organization Profiled', { 'Organization Name': orgName });
        segment.track('Role Profiled', { Role: userRole });
        segment.identify({ Role: userRole });
        setResError('');
        setIsAdminRequiredError(false);
        setOrgNameRequiredError(false);
        setLoading(true);
        let orgRes;
        try {
            // backend requested we don't try to create an org if it exists
            // causes long loading times and issues with parse
            const shouldCreateOrg = !orgId;
            if (shouldCreateOrg) {
                orgRes = yield saasApi.organizations.createOrganization({
                    type: orgType,
                    name: orgName,
                    role: userRole,
                    placeId: orgId,
                });
                const orgResData = orgRes.data;
                if (!orgResData.organization) {
                    if (orgResData.error &&
                        orgResData.error.error &&
                        orgResData.error.error.content &&
                        orgResData.error.error.content.errorCode === 'adminAlreadyExists') {
                        setResError('adminAlreadyExists');
                    }
                    else {
                        setResError('unexpected');
                    }
                    return;
                }
            }
            yield avaApi.users.updateRole({
                avaId: window.localStorage.getItem('avaId') || '',
                firebaseAuthUID: firebaseUser.uid,
                role: userRole,
            });
        }
        catch (e) {
            setResError('unexpected');
            return;
        }
        finally {
            setLoading(false);
        }
        const avaId = window.localStorage.getItem('avaId');
        // We trigger Org trial, but we ignore its effects to shorten the time
        // it takes for the users to onboard. In case this fails it is still not
        // actionable for the users, and should not block them from accessing the
        // app.
        triggerOrgTrial(avaId || '', firebaseUser.uid).catch((e) => {
            // This backend call fails most of the time, but its failure is
            // inconsequential to the users so we ignore it.
            console.log(`failed to trigger org trial: ${e}`);
        });
        const params = Object.assign(Object.assign({ chn: 'inb', src: 'premium', med: window.isElectron ? 'cc' : 'webapp', mkt: orgType, avaid: avaId, name, orgname: orgName, role: userRole, utm_campaign: window.utm_parameters && window.utm_parameters.name }, (firebaseUser.email && { email: firebaseUser.email })), (firebaseUser.phoneNumber && { phone: firebaseUser.phoneNumber }));
        let baseUrl;
        switch (language) {
            case 'fr':
                baseUrl = 'https://ava-me.typeform.com/to/R8L5rC';
                break;
            case 'en':
            default:
                baseUrl = 'https://ava-me.typeform.com/to/q2n11h';
                break;
        }
        const url = `${baseUrl}${Object.keys(params).reduce((acc, cur) => `${acc}&${cur}=${params[cur]}`, '?')}#userid=${LocalStorage.get(STORAGE_KEYS.AVA_ID) || ''}`;
        if (isAdmin === 'yes') {
            analytics.logEvent('app_submitted_typeform', {
                channel: 'inbound',
                source: 'onboarding',
                market: orgType,
                plan: 'Free',
                organization_name: orgName,
            });
        }
        if (window.desktopIntegration && isAdmin === 'yes') {
            window.desktopIntegration.openExternalURL(url);
            window.location.href = '/';
        }
        else if (!window.isElectron && isAdmin === 'yes') {
            window.location.href = url;
        }
        else if (window.isElectron && isAdmin !== 'yes') {
            createConverstionAndRedirectToTranscript();
        }
        else if (!window.isElectron && isAdmin !== 'yes') {
            const locationArray = location.pathname.split('/');
            navigate(`/${locationArray[1]}/onboarding/profile/app-bridge`);
        }
    });
    const openOrDownloadAvaAppFun = () => {
        // Since it's based on rendering a component,
        // we toggle the visibility alltogether
        setOpenOrDownloadAvaApp(false);
        setTimeout(() => {
            setOpenOrDownloadAvaApp(true);
        }, 0);
    };
    let organizationSearchResultPicto;
    switch (orgType) {
        case 'edu':
            organizationSearchResultPicto = eduOrg;
            break;
        case 'event':
            organizationSearchResultPicto = eventOrg;
            break;
        default:
            organizationSearchResultPicto = hrOrg;
            break;
    }
    if (loading) {
        return React.createElement(Loading, null);
    }
    return (React.createElement(Routes, null,
        React.createElement(Route, { element: React.createElement(Wrapper, null) },
            React.createElement(Route, { path: "hearing-profile", element: React.createElement("div", { className: classes.hearingProfileCardContainer },
                    React.createElement("div", { className: classes.hearingProfileCard },
                        React.createElement("div", { className: classes.topContainer },
                            React.createElement(Typography, { variant: "h4", align: "center", className: classes.title }, t('profiling.hearingProfile.heading')),
                            React.createElement(Typography, { className: classes.textUnderTitle, variant: "body1", align: "center" }, t('profiling.hearingProfile.body'))),
                        React.createElement("div", { className: classes.fabContainer },
                            React.createElement(Fab, { variant: "extended", className: classes.fab, onClick: () => setHearingProfileFun(HEARING_PROFILES.DEAF) },
                                React.createElement("div", { className: classes.fabContent },
                                    React.createElement("img", { src: deafASL, alt: t('profiling.hearingProfile.deaf') }),
                                    React.createElement("div", { className: classes.fabText },
                                        React.createElement(PhraseMarkdownText, { phraseKey: "profiling.hearingProfile.deaf" })))),
                            React.createElement(Fab, { variant: "extended", className: classes.fab, onClick: () => setHearingProfileFun(HEARING_PROFILES.HARD_OF_HEARING) },
                                React.createElement("div", { className: classes.fabContent },
                                    React.createElement("img", { src: deafHOH, alt: t('profiling.hearingProfile.hoh') }),
                                    React.createElement("div", { className: classes.fabText },
                                        React.createElement(PhraseMarkdownText, { phraseKey: "profiling.hearingProfile.hoh" })))),
                            React.createElement(Fab, { variant: "extended", className: classes.fab, onClick: () => setHearingProfileFun(HEARING_PROFILES.HEARING) },
                                React.createElement("div", { className: classes.fabContent },
                                    React.createElement("img", { src: hearing, alt: t('profiling.hearingProfile.hearing') }),
                                    React.createElement("div", { className: classes.fabText },
                                        React.createElement(PhraseMarkdownText, { phraseKey: "profiling.hearingProfile.hearing" }))))))) }),
            React.createElement(Route, { path: "voicing-profile", element: React.createElement("div", { className: classes.hearingProfileCardContainer },
                    React.createElement("div", { className: classes.topContainer },
                        React.createElement(Typography, { variant: "h4", align: "center", className: classes.title }, t('profiling.voicingProfile.heading')),
                        React.createElement(Typography, { width: '100%', className: classes.textUnderTitle, variant: "body1", align: "center" }, t('profiling.voicingProfile.body'))),
                    React.createElement("div", { className: classNames([classes.fabContainer, classes.fabBoxContainer]) },
                        React.createElement(Fab, { variant: "extended", className: classNames([classes.fab, classes.fabBox]), onClick: () => setVoicingProfile(VOICING_PROFILES.VOICING) },
                            React.createElement("img", { src: always, alt: t('profiling.voicingProfile.yes') }),
                            React.createElement("div", { className: classNames([classes.fabText, classes.fabBoxText]) },
                                React.createElement(PhraseMarkdownText, { phraseKey: "profiling.voicingProfile.yes" }))),
                        React.createElement(Fab, { variant: "extended", className: classNames([classes.fab, classes.fabBox]), onClick: () => setVoicingProfile(VOICING_PROFILES.NOT_VOICING) },
                            React.createElement("img", { src: notAlways, alt: t('profiling.voicingProfile.notAlways') }),
                            React.createElement("div", { className: classNames([classes.fabText, classes.fabBoxText]) },
                                React.createElement(PhraseMarkdownText, { phraseKey: "profiling.voicingProfile.notAlways" }))))) }),
            React.createElement(Route, { path: "gender-selection", element: React.createElement("div", { className: classNames(classes.hearingProfileCardContainer, classes.maxHeight) },
                    React.createElement("div", { className: classes.topContainer },
                        React.createElement(Typography, { variant: "h4", align: "center", width: '100%', className: classNames([classes.title, classes.fullWidth]) }, t('profiling.genderSelection.heading')),
                        React.createElement(Typography, { className: classNames([classes.textUnderTitle, classes.fullWidth]), variant: "body1", align: "center" }, t('profiling.genderSelection.body'))),
                    React.createElement("div", { className: classNames([classes.fabContainer, classes.fabBoxContainer]) },
                        React.createElement(TtsGenderFab, { redirect: true })),
                    React.createElement("div", { className: classes.laterContainer },
                        React.createElement(Button, { className: classes.later, onClick: () => {
                                const locationArray = location.pathname.split('/');
                                navigate(`/${locationArray[1]}/onboarding/profile/org-type`);
                            } }, t('profiling.later')))) }),
            React.createElement(Route, { path: "voice-selection", element: React.createElement("div", { className: classNames([classes.hearingProfileCardContainer, classes.voicSelectionContainer]) },
                    React.createElement("div", { className: classes.topContainer },
                        React.createElement(Typography, { variant: "h4", align: "center", width: '100%', className: classes.title }, t('profiling.voiceSelection.heading')),
                        React.createElement(Typography, { className: classes.textUnderTitle, width: '100%', variant: "body1", align: "center" }, t('profiling.voiceSelection.body'))),
                    React.createElement("div", { className: classNames([classes.fabContainer, classes.fabContainerVoiceSelection]) },
                        React.createElement(TtsVoiceFab, { redirect: true })),
                    React.createElement("div", { className: classes.laterContainer },
                        React.createElement(Button, { onClick: () => {
                                const locationArray = location.pathname.split('/');
                                navigate(`/${locationArray[1]}/onboarding/profile/org-type`);
                            }, className: classes.later }, t('profiling.later')))) }),
            React.createElement(Route, { path: "org-type", element: React.createElement("div", { className: classNames(classes.hearingProfileCardContainer, classes.maxHeight) },
                    React.createElement("div", { className: classes.topContainer },
                        React.createElement(Typography, { variant: "h4", align: "center", className: classes.title }, t('profiling.orgType.heading')),
                        React.createElement(Typography, { className: classes.textUnderTitle, variant: "body1", align: "center" }, t('profiling.orgType.body'))),
                    React.createElement(Grid, { className: classes.grid, spacing: 4, container: true },
                        React.createElement(Grid, { item: true, className: classes.gridItemContainer },
                            React.createElement("div", { className: classes.gridItem },
                                React.createElement(Link, { to: "../org-info", type: "button", className: classNames([classes.fab, classes.fabBoxOrg]), onClick: () => setOrgTypeFun('hr'), style: { color: 'rgb(75, 75, 75' } },
                                    React.createElement(Typography, { variant: "h6", align: "center", className: classNames([classes.title, classes.smallerTitle]), gutterBottom: true }, t('profiling.orgType.work')),
                                    React.createElement("img", { src: Work, alt: "Laptop with Ava captioning on screen", className: classes.orgTypeIllustration })))),
                        React.createElement(Grid, { item: true, className: classes.gridItemContainer },
                            React.createElement("div", { className: classes.gridItem },
                                React.createElement(Link, { to: "../org-info", type: "button", className: classNames([classes.fab, classes.fabBoxOrg]), onClick: () => setOrgTypeFun('edu'), style: { color: 'rgb(75, 75, 75' } },
                                    React.createElement(Typography, { variant: "h6", align: "center", className: classNames([classes.title, classes.smallerTitle]), gutterBottom: true }, t('profiling.orgType.school')),
                                    React.createElement("img", { src: School, alt: "Laptop with Ava captioning on screen", className: classes.orgTypeIllustration })))),
                        React.createElement(Grid, { item: true, className: classes.gridItemContainer },
                            React.createElement("div", { className: classes.gridItem },
                                React.createElement(Link, { to: "../org-info", type: "button", className: classNames([classes.fab, classes.fabBoxOrg]), onClick: () => setOrgTypeFun('event'), style: { color: 'rgb(75, 75, 75' } },
                                    React.createElement(Typography, { variant: "h6", align: "center", className: classNames([classes.title, classes.smallerTitle]), gutterBottom: true }, t('profiling.orgType.event')),
                                    React.createElement("img", { src: Event, alt: "Laptop with Ava captioning on screen", className: classes.orgTypeIllustration })))),
                        React.createElement(Grid, { item: true, className: classes.gridItemContainer },
                            React.createElement("div", { className: classes.gridItem },
                                React.createElement("button", { type: "button", className: classNames([classes.fab, classes.fabBoxOrg]), onClick: () => setOrgTypeFun('video'), style: { color: 'rgb(75, 75, 75' } },
                                    React.createElement(Typography, { variant: "h6", align: "center", className: classNames([classes.title, classes.smallerTitle]), gutterBottom: true }, t('profiling.orgType.video')),
                                    React.createElement("img", { src: Video, alt: "Laptop with Ava captioning on screen", className: classes.orgTypeIllustration })))))) }),
            React.createElement(Route, { path: "org-info", element: React.createElement("div", { className: classes.hearingProfileCardContainer },
                    React.createElement("form", { className: classes.orgInfoOptionsWrap },
                        React.createElement(Typography, { variant: "h4", align: "center", className: classes.title, gutterBottom: true }, t(`profiling.orgInfo.heading.${orgType}`)),
                        React.createElement(Typography, { variant: "body1", align: "center", gutterBottom: true }, t(`profiling.orgInfo.body`)),
                        React.createElement(FormControl, { variant: "standard", className: classes.formControl, component: "fieldset", margin: "normal" },
                            React.createElement("div", { style: { position: 'relative' } },
                                React.createElement(TextField, { required: true, fullWidth: true, value: orgName || inputOrgName, onChange: (e) => setOrgNameFun(e), label: t(`profiling.orgInfo.orgName.label.${orgType}`), variant: "outlined", error: resError === 'adminAlreadyExists', InputProps: {
                                        className: orgName && classes.searchFieldWithPadding,
                                    }, onFocus: () => {
                                        setOrgInputFocused(true);
                                    }, onBlur: () => {
                                        // Blur after timeout
                                        // so time to select value
                                        setTimeout(() => {
                                            setOrgInputFocused(false);
                                            setOrgName(orgName || inputOrgName);
                                        }, 150);
                                    } }),
                                orgName && (React.createElement("img", { className: classes.searchResultTypePicto, alt: "search result picto", src: orgId ? organizationSearchResultPicto : addOrg })),
                                currentlySearchingOrganizations && (React.createElement(CircularProgress, { size: 20, className: classes.orgaSearchProgress })),
                                orgInputFocused && inputOrgName && organizationsSearchResults && (React.createElement(OrganizationsSearchResults, { searchResults: organizationsSearchResults, query: inputOrgName, onSelectResult: (result) => {
                                        setOrgName(result.name);
                                        setOrgId(result.id);
                                    }, orgType: orgType }))),
                            orgNameRequiredError && (React.createElement(FormHelperText, { error: true, className: classes.helperText }, t('profiling.orgInfo.required'))),
                            resError === 'adminAlreadyExists' && (React.createElement(FormHelperText, { error: true, className: classes.helperText }, t('profiling.orgInfo.res.adminAlreadyExists')))),
                        React.createElement(FormControl, { variant: "standard", component: "fieldset", margin: "none", required: true },
                            React.createElement(FormLabel, { component: "legend", className: classes.formLabel }, t(`profiling.orgInfo.isAdmin.${orgType}`)),
                            React.createElement(RadioGroup, { value: isAdmin, onChange: (e) => setIsAdminFun(e), row: true },
                                React.createElement(FormControlLabel, { value: "yes", control: React.createElement(Radio, null), label: t('profiling.orgInfo.isAdmin.yes') }),
                                React.createElement(FormControlLabel, { value: "no", control: React.createElement(Radio, null), label: t('profiling.orgInfo.isAdmin.no') }),
                                isAdminRequiredError && (React.createElement(FormHelperText, { error: true, className: classes.checkboxHelperText }, t('profiling.orgInfo.required'))))),
                        React.createElement(Button, { type: "submit", variant: "contained", color: "primary", className: classes.button, onClick: (e) => submit(e), disabled: !orgName },
                            loading && React.createElement(CircularProgress, { size: 20, color: "secondary" }),
                            !loading && t('profiling.orgInfo.continue')),
                        resError === 'newTrialError' && (React.createElement(FormHelperText, { error: true, className: classes.centerText }, t(`profiling.orgInfo.res.newTrialError`))),
                        resError === 'unexpected' && (React.createElement(FormHelperText, { error: true, className: classes.centerText }, t(`profiling.orgInfo.res.unexpectedError`))))) }),
            React.createElement(Route, { path: "app-bridge", element: React.createElement(React.Fragment, null,
                    React.createElement("div", { className: classes.loginImageContainer },
                        React.createElement("img", { className: classes.illustration, src: AvaLanding, alt: t('title') })),
                    React.createElement(Typography, { variant: "h4", className: classes.title, align: "center" }, t('profiling.appBridge.heading')),
                    React.createElement("div", { className: classes.lowerContainerAppBridge },
                        React.createElement(Button, { type: "submit", variant: "contained", color: "primary", className: classes.bigButton, onClick: () => openOrDownloadAvaAppFun(), style: { marginTop: 15 } }, t('profiling.appBridge.downloadAvaDesktop', { OS: getOS() })),
                        React.createElement(Typography, { variant: "subtitle1", align: "center" },
                            React.createElement("button", { className: classes.maybeLater, type: "button", onClick: () => {
                                    if (location.pathname.split('/')[1] === 'web') {
                                        createConverstionAndRedirectToTranscript();
                                    }
                                } }, t('profiling.appBridge.maybeLater')))),
                    openOrDownloadAvaApp && React.createElement(OpenOrDownloadAvaAppComponent, null)) }),
            React.createElement(Route, { path: "*", element: React.createElement(Navigate, { to: "/web/onboarding/profile/hearing-profile" }) }))));
};
export default ScribeLoginProfiling;
