import { useTheme } from '@mui/material/styles';
import classNames from 'classnames';
import React from 'react';
import { scribeSelectTranscriptWord } from '../../actions';
import { selectParticipantOnWord } from '../../selectors/combined';
import { selectCurrentCaptionsColor } from '../../selectors/transcriptSelectors';
import { useAppDispatch, useAppSelector } from '../../store/store';
import { parseWord } from '../../utils/transcripts';
const ScribeWord = ({ allowEdit, highlighted, scribe, transcriptId, unlocked, word, wordIndex, asrFinal, id, }) => {
    const theme = useTheme();
    const dispatch = useAppDispatch();
    const participantOnWord = useAppSelector((state) => selectParticipantOnWord(state, transcriptId, wordIndex));
    const captionColor = useAppSelector((state) => selectCurrentCaptionsColor(state, id, theme, wordIndex));
    const parsedWord = parseWord(word);
    return (React.createElement("span", null,
        React.createElement("span", { style: {
                whiteSpace: 'pre-wrap',
                backgroundColor: scribe && (!asrFinal || unlocked) ? theme.palette.ava.asrGrey : 'transparent',
                fontWeight: theme.palette.bold === 'true' || highlighted ? 600 : undefined,
                fontStyle: !highlighted && !asrFinal && scribe ? 'italic' : 'normal',
                color: captionColor,
            }, onClick: () => {
                if (allowEdit) {
                    dispatch(scribeSelectTranscriptWord({
                        index: wordIndex,
                        indexTranscript: transcriptId,
                        skipCommit: true,
                        skipJumpNextBloc: true,
                    }));
                }
            }, role: "button", "data-qa": "captions" },
            allowEdit && (participantOnWord === null || participantOnWord === void 0 ? void 0 : participantOnWord.avaId) && (React.createElement("span", { style: {
                    position: 'absolute',
                    fontSize: 12,
                    marginTop: -14,
                    borderLeft: 'solid',
                    borderWidth: 2,
                    borderColor: 'darkred',
                    paddingLeft: 3,
                    backgroundColor: 'rgba(10,10,10,0.5)',
                    pointerEvents: 'none',
                } }, participantOnWord.userName)),
            React.createElement("span", { className: classNames({
                    ['tr-edited']: parsedWord.bold,
                    ['tr-disabled']: parsedWord.disabled,
                }), id: participantOnWord === null || participantOnWord === void 0 ? void 0 : participantOnWord.userName, style: {
                    backgroundColor: participantOnWord ? 'darkred' : undefined,
                    color: (participantOnWord === null || participantOnWord === void 0 ? void 0 : participantOnWord.avaId) ? 'white' : undefined,
                } }, parsedWord.text)),
        ' '));
};
export default ScribeWord;
