/* eslint-disable no-shadow */
import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined';
import { Avatar } from '@mui/material';
import Link from '@mui/material/Link';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Toggle from 'react-toggled';
import EditProfileModal from '../../modules/scribe/EditProfileModal';
import { selectFirebaseUser } from '../../selectors/auth';
import { selectAvaName, selectDisplayId, selectDisplayName, selectOrgProperties, selectSubscription, selectUserName, selectUserPhotoURL, } from '../../selectors/userProfile';
import { useAppSelector } from '../../store/store';
import { getUserColor } from '../../utils/user';
import AvatarLogo from '../Icons/AvatarLogo';
function User() {
    const theme = useTheme();
    const { t } = useTranslation();
    const firebaseUser = useAppSelector(selectFirebaseUser);
    const userPhotoURL = useAppSelector(selectUserPhotoURL);
    const userName = useAppSelector(selectUserName);
    const displayName = useAppSelector(selectDisplayName);
    const displayId = useAppSelector(selectDisplayId);
    const avaName = useAppSelector(selectAvaName);
    const orgProperties = useAppSelector(selectOrgProperties);
    const subscription = useAppSelector(selectSubscription);
    const getSubscriptionDisplay = () => {
        if (!subscription)
            return '';
        const { ongoingSubscription, ongoingSubscriptionSubtype } = subscription;
        const subscriptionLabel = ongoingSubscription === 'Freedom' ? 'Community' : ongoingSubscription;
        return `Ava ${subscriptionLabel} ${ongoingSubscriptionSubtype}`;
    };
    return (React.createElement(ListItem, { style: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'start',
            outline: 'none',
        } },
        React.createElement("div", { style: { flexGrow: 1 } }),
        firebaseUser && userPhotoURL ? (React.createElement(Avatar, { src: userPhotoURL, alt: "profile_photo", style: {
                margin: 'auto',
                marginRight: 12,
                width: 36,
                height: 36,
                backgroundColor: getUserColor(userName),
            } }, userName && userName[0].toUpperCase())) : (React.createElement("div", { style: { margin: 'auto', marginRight: 12 } },
            React.createElement(AvatarLogo, { color: theme.palette.mode === 'light'
                    ? theme.palette.ava.navyGroup.convoText
                    : theme.palette.ava.navyGroup.convoTextDark, width: 40, height: 40 }))),
        firebaseUser && (React.createElement(ListItemText, { className: "hover-parent", style: {
                width: '100%',
            } },
            React.createElement(Toggle, null, ({ on, getTogglerProps, setOff }) => (React.createElement("div", { style: {
                    display: 'flex',
                    flexDirection: 'column',
                } },
                React.createElement("div", Object.assign({ style: {
                        display: 'flex',
                        flexDirection: 'row',
                        cursor: 'pointer',
                    } }, getTogglerProps()),
                    React.createElement(Typography, { variant: "h6", noWrap: true }, displayName)),
                on && React.createElement(EditProfileModal, { open: on, close: setOff })))),
            avaName && (React.createElement(Typography, { style: {
                    marginTop: -10,
                }, noWrap: true, color: "primary", variant: "subtitle1" },
                React.createElement("small", null,
                    React.createElement("strong", { "data-qa": "avaName" }, `&${avaName}`)))),
            React.createElement(Typography, { style: {
                    marginTop: -10,
                }, noWrap: true, color: "primary", variant: "subtitle1" },
                React.createElement("small", null, displayId)),
            subscription && subscription.ongoingSubscription && (React.createElement(Typography, { style: { marginTop: -10 }, variant: "subtitle1", noWrap: true },
                React.createElement("small", null, getSubscriptionDisplay()))),
            (orgProperties === null || orgProperties === void 0 ? void 0 : orgProperties.role) === 'admin' && (React.createElement(Typography, { style: {
                    marginTop: -10,
                }, noWrap: true, color: "secondary", variant: "subtitle1" },
                React.createElement("small", null,
                    React.createElement(Link, { style: {
                            color: '#777',
                            fontWeight: 600,
                        }, target: "_blank", rel: "noreferrer noopener", href: "/account/dashboard", underline: "hover" },
                        t('sidebar.adminDashboard'),
                        React.createElement(OpenInNewOutlinedIcon, { style: {
                                fontSize: '1em',
                                transform: 'translate(0.2em, 0.16em)',
                            } })))))))));
}
export default User;
