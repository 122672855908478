import React from 'react';
import BoostPopup from '../../components/Popups/BoostPopup';
import SaveTranscriptPopup from '../../components/Popups/SaveTranscriptPopup';
import { selectCurrentConversationEndingStage } from '../../selectors/conversationEnd';
import { activateNextEndConversationStage, ConversationEndingStage } from '../../store/slices/conversationEnd';
import { useAppDispatch, useAppSelector } from '../../store/store';
const ConversationEndModals = () => {
    const dispatch = useAppDispatch();
    const currentConversationEndingStage = useAppSelector(selectCurrentConversationEndingStage);
    const showBoostModal = currentConversationEndingStage === ConversationEndingStage.BOOST;
    const showSaveTranscriptsModal = currentConversationEndingStage === ConversationEndingStage.SAVE_TRANSCRIPTS;
    const handleBoostPopup = () => {
        dispatch(activateNextEndConversationStage());
    };
    const handleSaveTranscriptPopup = () => {
        dispatch(activateNextEndConversationStage());
    };
    return (React.createElement(React.Fragment, null,
        showSaveTranscriptsModal && React.createElement(SaveTranscriptPopup, { callback: handleSaveTranscriptPopup }),
        showBoostModal && React.createElement(BoostPopup, { open: showBoostModal, onClose: handleBoostPopup })));
};
export default ConversationEndModals;
