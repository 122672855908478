import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import RemoveOutlinedIcon from '@mui/icons-material/RemoveOutlined';
import { Box, ListItemIcon, Slider, Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { scribeSetFontSizeAndSave } from '../../actions';
import { selectFontSize } from '../../selectors/ui';
import { useAppDispatch, useAppSelector } from '../../store/store';
// When clicking the buttons to increase or decrease the font size,
// the current font size is multiplied by one of these
const DECREASE_FONT_SIZE_FACTOR = 0.93;
const INCREASE_FONT_SIZE_FACTOR = 1.1;
const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
    },
    stepSize: {
        color: theme.palette.contrast[theme.palette.mode],
        cursor: 'pointer',
        opacity: 0.9,
        minWidth: 0,
    },
    decreaseSize: {
        marginRight: 12,
    },
    increaseSize: {
        marginRight: 0,
        marginLeft: 12,
    },
    slider: {
        flexGrow: 1,
    },
}));
export function FontSizeInput({ className, onSizeChanged }) {
    const classes = useStyles();
    const { t } = useTranslation();
    const fontSize = useAppSelector(selectFontSize);
    const dispatch = useAppDispatch();
    const multiplyFontSize = useCallback((ratio) => {
        const newFontSize = Math.round(fontSize * ratio);
        dispatch(scribeSetFontSizeAndSave(newFontSize));
        onSizeChanged === null || onSizeChanged === void 0 ? void 0 : onSizeChanged();
    }, [fontSize, dispatch, scribeSetFontSizeAndSave, onSizeChanged]);
    const onDecreaseFontSize = useCallback(() => {
        multiplyFontSize(DECREASE_FONT_SIZE_FACTOR);
    }, [multiplyFontSize]);
    const onIncreaseFontSize = useCallback(() => {
        multiplyFontSize(INCREASE_FONT_SIZE_FACTOR);
    }, [multiplyFontSize]);
    const onChangeLogFontSize = useCallback((event, value) => {
        dispatch(scribeSetFontSizeAndSave(Math.pow(value, 10)));
        onSizeChanged === null || onSizeChanged === void 0 ? void 0 : onSizeChanged();
    }, [dispatch, scribeSetFontSizeAndSave, onSizeChanged]);
    const logFontSize = Math.log10(fontSize);
    return (React.createElement(Box, { className: classNames(classes.root, className) },
        React.createElement(Tooltip, { disableInteractive: true, title: t('theme.decreaseFontSize') },
            React.createElement(ListItemIcon, { className: classNames(classes.stepSize, classes.decreaseSize), onClick: onDecreaseFontSize },
                React.createElement(RemoveOutlinedIcon, null))),
        React.createElement(Slider, { className: classes.slider, value: logFontSize, onChange: onChangeLogFontSize, min: 1, max: 2.2, step: 0.05 }),
        React.createElement(Tooltip, { disableInteractive: true, title: t('theme.increaseFontSize') },
            React.createElement(ListItemIcon, { className: classNames(classes.stepSize, classes.increaseSize), onClick: onIncreaseFontSize },
                React.createElement(AddOutlinedIcon, null)))));
}
