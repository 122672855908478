import formatDate from 'date-fns/format';
import enUS from 'date-fns/locale/en-US';
import fr from 'date-fns/locale/fr';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { SnackbarProvider } from 'notistack';
import * as React from 'react';
import { lazy, useEffect, useState } from 'react';
import { initReactI18next } from 'react-i18next';
import TranslationEn from '../assets/translation/en.json';
import TranslationFr from '../assets/translation/fr.json';
import { SiteThemeProvider } from './contexts/SiteThemeProvider';
import { TauriIntegration } from './modules/tauri/TauriIntegration';
import AppRoutes from './routes';
import { selectFirebaseUser } from './selectors/auth';
import { selectElectronCaptionMode } from './selectors/ui';
import { selectAvaId } from './selectors/userProfile';
import { useAppSelector } from './store/store';
import { getUrlParams } from './utils';
import { langs } from './utils/i18n';
import { boot, unreadCountChange } from './utils/intercom';
import { init } from './utils/log';
// if we don't lazy load them the whole app crashes
const soloDia = lazy(() => import('./modules/customNotifications/SoloDiaNotification'));
const soloDiaSuccess = lazy(() => import('./modules/customNotifications/SoloDiaSuccess'));
init()
    // eslint-disable-next-line
    .then(console.log)
    // eslint-disable-next-line
    .catch(console.error);
i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .use(LanguageDetector)
    .init({
    resources: {
        en: {
            translation: TranslationEn,
        },
        fr: {
            translation: TranslationFr,
        },
    },
    detection: {
        order: ['cookie', 'localStorage', 'navigator'],
        caches: ['localStorage', 'cookie'],
    },
    lngs: langs,
    fallbackLng: 'en',
    interpolation: {
        escapeValue: false,
        format: (value, format, lng) => {
            if (format === 'uppercase') {
                return value.toUpperCase();
            }
            if (format === 'number') {
                return value.toLocaleString(lng);
            }
            if (value instanceof Date) {
                return formatDate(value, format, { locale: lng === 'fr' ? fr : enUS });
            }
            return value;
        },
    },
}, (err, t) => {
    window.document.title = t('title');
});
const urlParams = getUrlParams();
if (urlParams.lang === 'fr') {
    i18n.changeLanguage('fr');
}
export const AppWrapper = ({ children }) => {
    const firebaseUser = useAppSelector(selectFirebaseUser);
    const avaId = useAppSelector(selectAvaId);
    const electronCaptionMode = useAppSelector(selectElectronCaptionMode);
    const [unreadIntercomMessages, setUnreadIntercomMessages] = useState(0);
    useEffect(() => {
        boot({
            app_id: 'eko73i1t',
            created_at: new Date(),
            user_id: avaId,
            email: firebaseUser === null || firebaseUser === void 0 ? void 0 : firebaseUser.email,
            horizontal_padding: 80,
        });
        // As the app loads - hide intercom messenger, but allow other widgets
        document.documentElement.style.setProperty('--intercom-messenger-display', 'none');
        document.documentElement.style.setProperty('--intercom-display', 'block');
        // unreadCountChange can only run successfully if it is called after `boot`
        // successfully loads intercom. However there is no way to check when that happens.
        // So we give Intercom generous 5 seconds to load.
        setTimeout(() => 
        // There is no way to stop listening on the intercom events
        unreadCountChange(setUnreadIntercomMessages), 5000);
    }, []);
    useEffect(() => {
        // Programatically hiding and showing intercom is very hard. 'shutdown' and 'boot'
        // seem to work, but only if 'shutdown' is called after 'boot' finishes initializing
        // which is impossible to determine programmatically. hide_default_launcher seems
        // to be just plainly ignored. So we are relying on CSS classes and variables
        // to forcefully hide it.
        // See WEB-1381 for expected behavior.
        // Allow any intercom widgets to be shown if not in CCMode. Messenger will still
        // be hidden by default using intercom-messenger-display until explicitly shown.
        document.documentElement.style.setProperty('--intercom-display', electronCaptionMode ? 'none' : 'block');
    }, [electronCaptionMode]);
    useEffect(() => {
        if (unreadIntercomMessages !== 0) {
            // This will only work if intercom-display is enabled, so we are outside CC mode.
            document.documentElement.style.setProperty('--intercom-messenger-display', `block`);
        }
    }, [unreadIntercomMessages]);
    return (React.createElement(SiteThemeProvider, null,
        React.createElement(SnackbarProvider, { preventDuplicate: true, iconVariant: {
                warning: '',
            }, maxSnack: 3, anchorOrigin: {
                vertical: 'top',
                horizontal: 'left',
            }, autoHideDuration: 5000, classes: {
                root: 'a-snackbar',
            }, disableWindowBlurListener: true, Components: {
                soloDia,
                soloDiaSuccess,
            } },
            React.createElement(TauriIntegration, null),
            children)));
};
const App = () => React.createElement(AppRoutes, null);
export default App;
