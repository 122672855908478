import { makeStyles } from '@mui/styles';
import React from 'react';
import { FontSizeInput } from '../../components/Inputs/FontSizeInput';
const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        '& .MuiSlider-thumb': {
            backgroundColor: theme.palette.contrast[theme.palette.mode],
            height: 12,
            width: 12,
        },
        '& .MuiSlider-rail': {
            backgroundColor: theme.palette.contrast[theme.palette.mode],
        },
        '& .MuiSlider-track': {
            backgroundColor: theme.palette.contrast[theme.palette.mode],
            border: 'none',
        },
    },
}));
export function ConvoV2FontSizeInput() {
    const classes = useStyles();
    return React.createElement(FontSizeInput, { className: classes.root });
}
